import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const badgeAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.BADGE, data);
  return response;
};

export const badgeEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.BADGE, data);
  return response;
};

export const badgeRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.BADGE + `/${id}`);
  return response;
};

export const badge = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.BADGE}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};
