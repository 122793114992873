import HttpService from "../helper/HttpService";
import { ApiUrl } from "../components/Common/ApiUrl";

export const loginUser = async (data) => {
  const response = await HttpService.post(ApiUrl.LOGIN, data);
  return response;
};

export const logoutUser = async () => {
  const response = await HttpService.get(ApiUrl.LOGOUT);
  return response;
};
