import { types } from "../../Actions/types";

const initialState = {
  forcelist: [],
  error: null,
  forceSingle: "",
  popupmodel: "",
};

const Force = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.FORCE_LIST:
      return {
        ...state,
        forcelist: payload,
        error,
      };
    case types.FORCE_SINGLE:
      return {
        ...state,
        forceSingle: payload,
        error,
      };
    case types.FORCE_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default Force;
