import { React } from "react";
import { Formik, Form } from "formik";
import AuthLeft from "./AuthLeft";
import TextField from "../Common/Fields/TextField";
import PasswordFieldWithToggle from "../Common/Fields/PasswordFieldWithToggle";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { Constants } from "../Common/Constants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { RoutesUrl } from "../Common/RoutesUrl";
import * as Yup from "yup";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/Actions/Auth";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.Auth);
  const initialValues = { email: "", password: "" };

  // validation schema for Signin form
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(7, Constants.passwordRequired)
      .matches(Constants.validPasswordRegex, Constants.passwordValidation)
      .required(Constants.required),
    email: Yup.string()
      .required(Constants.required)
      .matches(Constants.validEmailRegex, Constants.invalidEmail),
  });

  useEffect(() => {
    if (isLoggedIn === true) {
      navigate(RoutesUrl.dashboard);
    }
  }, [isLoggedIn]);

  return (
    <div className="main ">
      <div className="body d-flex ">
        <div className="row g-0 h-100vh">
          <AuthLeft />
          <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg ">
            <div
              className="w-100  p-md-5 card-sp card border-0 shadow-sm login-form"
              style={{ maxwidth: "32rem" }}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  dispatch(login(values)).then((response) => {
                    if (response.error) {
                      dispatch({
                        type: types.ERROR_ALERT_VISIBLE,
                        payload: response.error,
                      });
                    }
                  });
                }}
              >
                {({ errors, touched }) => (
                  <Form className="row g-1 p-md-4 ">
                    <h2 className="text-center fw-bold">{Constants.login}</h2>
                    <div className="col-12">
                      <TextField
                        type="email"
                        name="email"
                        placeholder="name@example.com"
                        autoComplete="off"
                        className="form-control form-control-lg lift"
                        label={Constants.email}
                        errors={touched?.email ? errors?.email : undefined}
                        touched={touched?.email}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        type="password"
                        name="password"
                        // Hide & show password component
                        component={PasswordFieldWithToggle}
                        placeholder="***************"
                        className="form-control form-control-lg lift"
                        label={Constants.password}
                        errors={
                          touched?.password ? errors?.password : undefined
                        }
                        touched={touched?.password}
                      />
                    </div>
                    <ButtonCustom label={Constants.login} />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
