import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import {
  equipmentInsert,
  equipmentList,
  equipmentUpdate,
} from "../../Redux/Actions";

const EquipmentForm = () => {
  const dispatch = useDispatch();
  const { equipmentSingle } = useSelector((state) => state.Equipment);
  // Initial values to Equipment form
  const [initialValues, setInitialValues] = useState({
    name: "",
  });

  // Validations for Equipment form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill equipmentSingle form
    if (equipmentSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (equipmentSingle[key]) {
          editValue[key] = equipmentSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [equipmentSingle]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update equipmentSingle API
        let submitFunc = equipmentInsert(values);
        if (equipmentSingle._id) {
          values.id = equipmentSingle._id;
          submitFunc = equipmentUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.EQUIPMENT_SINGLE, payload: "" });
            dispatch({ type: types.EQUIPMENT_FORM_MODEL, payload: "" });
          }
          dispatch(equipmentList());
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default EquipmentForm;
