import { fetchUserDetail, users } from "../../services/users.service";
import { types } from "./types";

export const usersList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await users(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.USERS_LIST, payload: result?.users });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.USERS_LIST,
      payload: [],
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const getUserDetail = (userId, days) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await fetchUserDetail(userId, days);
  if (response.status === true) {
    const result = response?.data?.response;
    dispatch({ type: types.USER_DETAIL, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.USER_DETAIL,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
