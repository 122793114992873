import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "../../Common/Constants";

const TypeEnum = [Constants.byMuscle, Constants.byEquipment, Constants.byForce];

const FilterExercises = ({ selectedFilters, onHide, applyFilters }) => {
  const [selectedFilter, setSelectedFilter] = useState(TypeEnum[0]);
  const [selectedItems, setSelectedItems] = useState(selectedFilters);
  const { forcelist } = useSelector((state) => state.Force);
  const { equipmentlist } = useSelector((state) => state.Equipment);
  const { primarymusclelist } = useSelector((state) => state.PrimaryMuscle);

  useEffect(() => {
    setSelectedItems(selectedFilters);
  }, [selectedFilters]);

  const handleCheckboxChange = (itemId, filterType) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedItems = { ...prevSelectedItems };
      if (updatedItems[filterType].includes(itemId)) {
        updatedItems[filterType] = updatedItems[filterType].filter(
          (id) => id !== itemId
        );
      } else {
        updatedItems[filterType] = [...updatedItems[filterType], itemId];
      }
      return updatedItems;
    });
  };

  const handleApply = () => {
    applyFilters(selectedItems);
  };

  const getItemList = () => {
    switch (selectedFilter) {
      case Constants.byMuscle:
        return { list: primarymusclelist?.items, type: "primaryMuscles" };
      case Constants.byForce:
        return { list: forcelist?.items, type: "force" };
      case Constants.byEquipment:
        return { list: equipmentlist?.items, type: "equipment" };
      default:
        return { list: [], type: "" };
    }
  };

  const { list, type } = getItemList(
    selectedFilter,
    primarymusclelist,
    forcelist,
    equipmentlist
  );

  return (
    <div>
      <div className="flex_tb">
        <div className="tab_side">
          <ul className="nav nav-pills">
            {TypeEnum.map((filter) => (
              <li
                key={filter}
                className="nav-item cursor-pointer"
                onClick={() => setSelectedFilter(filter)}
              >
                <span
                  className={`nav-link ${
                    selectedFilter === filter ? "active" : ""
                  }`}
                >
                  {filter}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="content_side" id="style-2">
          <div className="outer_muscle">
            <ul className="nav d-block list_checkbox custom_checkbox">
              {list?.map((item) => (
                <li key={item?._id}>
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id={`checkbox-${item._id}`}
                      checked={selectedItems[type]?.includes(item._id)}
                      onChange={() => handleCheckboxChange(item._id, type)}
                    />
                    <label htmlFor={`checkbox-${item._id}`}>{item?.name}</label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer_modal modal-pad">
        <div className="flex_btns">
          <button className="btn btn_apply btn_cancel" onClick={onHide}>
            {Constants.cancel}
          </button>
          <button className="btn btn_apply" onClick={handleApply}>
            {Constants.apply}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterExercises;
