import React from "react";
import { useDrag } from "react-dnd";

const DraggableWorkout = ({ item }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "workout",
    item: { id: item._id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      className="exer-view"
      ref={drag}
      style={{
        padding: "8px",
        margin: "4px",
        backgroundColor: "lightblue",
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <div className="exer-img">
        <img src={item?.thumbnail} alt={item?.name} />
      </div>
      <p className="exer-name">{item.name}</p>
    </div>
  );
};

export default DraggableWorkout;
