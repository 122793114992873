import React from "react";
import ReactPaginate from "react-paginate";

const ReactPagination = (props) => {
  // Calculate the total number of pages
  const pageCount = Math.ceil(props?.total / props?.itemsPerPage);

  // Function to handle page click
  const handlePageClick = (event) => {
    if (props?.page) {
      props?.page(event?.selected);
    }
    // Calculate the new offset based on the selected page and items per page
    const newOffset = (event?.selected * props?.itemsPerPage) % props?.total;
    props.newOffset(newOffset);
  };

  return (
    <div className="react-pagination-btm">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        forcePage={props?.pageIndex}
      />
    </div>
  );
};

export default ReactPagination;
