import React from "react";
import { useDrop } from "react-dnd";
import { Constants } from "../../Common/Constants";

const DroppableDay = ({ day, onDrop, deleteWorkout }) => {
  const [{ isOver }, drop] = useDrop({
    accept: "workout",
    drop: (item) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className={`list-cards ${isOver ? "highlight" : ""}`}>
      <div className="img_name">
        <div className="img_round">
          <i className="icofont-dumbbell"></i>
        </div>
        <span>{`Day ${day?.day}`}</span>
      </div>
      <div className="name_ex">
        <p>{day?.workout?.name || Constants.na}</p>
      </div>
      {day?.workout?.name && (
        <div className="img_dots">
          <button
            className="trash-btns"
            type="button"
            onClick={() => deleteWorkout(day)}
          >
            <i className="icofont-trash"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default DroppableDay;
