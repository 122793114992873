export const Constants = {
  accepts: "Accepts",
  accessToken: "accessToken",
  actions: "Actions",
  activeTab: "activeTab",
  activityType: "Activity Type",
  activityTypeOptions: [
    { value: "Weight Lift", label: "Weight Lift" },
    { value: "Reps", label: "Reps" },
    { value: "Workout Duration", label: "Workout Duration" },
    { value: "Workout Count", label: "Workout Count" },
    { value: "Streaks", label: "Streaks" },
  ],
  add: "Add",
  addBadge: "Add Badge",
  addBodyGroup: "Add Body Group",
  addChallenge: "Add Challenge",
  addEquipment: "Add Equipment",
  addExercise: "Add Exercise",
  addExerciseCategory: "Add Exercise Category",
  addExercisePosition: "Add Exercise Position",
  addExerciseGoal: "Add Exercise Goal",
  addFaq: "Add FAQ",
  addForce: "Add Force",
  addGoal: "Add Goal",
  addJoint: "Add Avoid for Joint",
  addMechanicsJoint: "Add Joint",
  addMovementPattern: "Add Movement Pattern",
  addMuscle: "Add Avoid for Muscle",
  addPrimaryJoint: "Add Primary Joint",
  addPrimaryMuscle: "Add Primary Muscle",
  addProgramme: "Add Programme",
  addSynergistMuscle: "Add Synergist Muscle",
  addTutorial: "Add Tutorial",
  addWorkout: "Add Workout",
  admin: "Admin",
  age: "Age",
  analyticsMonth: "30",
  analyticsNinetyDays: "90 days",
  analyticsOneYear: "1 year",
  analyticsSevenDays: "7 days",
  analyticsThirtyDays: "30 days",
  analyticsThreeMonths: "90",
  analyticsWeek: "7",
  analyticsYear: "365",
  ans: "Answer",
  apply: "Apply",
  areYouSure: "Are you sure?",
  back: "Back",
  badgeList: "Badge List",
  badges: "Badges",
  badgesKey: "badges",
  badgeSubTypeOptions: [
    { value: "reps", label: "Reps" },
    { value: "timeUnderTension", label: "Time Under Tension" },
    { value: "weightLifted", label: "Weight Lifted" },
    { value: "caloriesBurned", label: "Calories Burned" },
  ],
  badgeTypeOptions: [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "lifetime", label: "Lifetime" },
    { value: "workout", label: "Workout" },
    { value: "streak", label: "Streak" },
    { value: "muscleGroup", label: "Muscle Group" },
  ],
  bodyGroup: "Body Group",
  bodyGroupKey: "body-group",
  bodyGroupList: "Body Group List",
  bodyParts: "Body Parts",
  bodyPartSpecific: "Body Part Specific",
  byEquipment: "By Equipment",
  byForce: "By Force",
  byMuscle: "By Muscle",
  cancel: "Cancel",
  category: "Category",
  challenge: "Challenge",
  challengeKey: "challenge",
  challengeList: "Challenge List",
  cms: "CMS",
  code: "Code",
  compoundSet: "compoundset",
  createdDate: "Created Date",
  createdTime: "Created Time",
  customer: "Customers",
  customerList: "Customer List",
  dashboard: "Dashboard",
  dashboardKey: "dashboard",
  dateFormat: "D MMM YYYY",
  delete: "Delete",
  deleted: "Deleted",
  description: "Description",
  detail: "Detail",
  dob: "Date of birth",
  edit: "Edit",
  editBadge: "Edit Badge",
  editBodyGroup: "Edit Body Group",
  editChallenge: "Edit Challenge",
  editDayWorkout: "Edit Day Workout",
  editEquipment: "Edit Equipment",
  editExerciseCategory: "Edit Exercise Category",
  editExercise: "Edit Exercise",
  editExerciseGoal: "Edit Exercise Goal",
  editExercisePosition: "Edit Exercise Position",
  editFaq: "Edit FAQ",
  editForce: "Edit Force",
  editGoal: "Edit Goal",
  editJoint: "Edit Avoid for Joint",
  editMechanicsJoint: "Edit Joint",
  editMovementPattern: "Edit Movement Pattern",
  editMuscle: "Edit Avoid for Muscle",
  editPrimaryJoint: "Edit Primary Joint",
  editPrimaryMuscle: "Edit Primary Muscle",
  editProgramme: "Edit Programme",
  editSynergistMuscle: "Edit Synergist Muscle",
  editTutorial: "Edit Tutorial",
  editWorkout: "Edit Workout",
  email: "Email",
  endDate: "End Date",
  equipment: "Equipment",
  equipmentKey: "equipment",
  equipmentList: "Equipment List",
  exercise: "Exercise",
  exerciseKey: "exercise",
  exerciseList: "Exercise List",
  exerciseCategory: "Exercise Category",
  exerciseCategoryKey: "exer-category",
  exerciseCategoryList: "Exercise Category List",
  exerciseGoal: "Exercise Goal",
  exerciseGoalKey: "exer-goal",
  exerciseGoalList: "Exercise Goal List",
  exerciseName: "Exercise Name",
  exerciseCode: "Exercise Code",
  exerciseCodePs: "Exercise Code Ps",
  exercisePosition: "Exercise Position",
  exercisePositionKey: "exer-position",
  exercisePositionList: "Exercise Position List",
  experience: "Experience Level",
  export: "EXPORT",
  faq: "FAQ",
  faqKey: "faq",
  faqList: "FAQ List",
  force: "Force",
  forceKey: "force",
  forceList: "Force List",
  goalKey: "goals",
  goalList: "Goal List",
  goals: "Goal",
  goalTiming: "Goal TIming",
  goalTimingKey: "goal-timing",
  goalTimingType: [
    {
      value: "WEEKS",
      label: "Weeks",
    },
    {
      value: "MONTHS",
      label: "Months",
    },
    {
      value: "YEARS",
      label: "Years",
    },
  ],
  gender: "Gender",
  gridKey: "grid",
  guidedVideo: "Guided Video",
  height: "Height",
  heightTypeCm: "CMS",
  heightTypeFt: "FEET",
  history: "History",
  historyKey: "history",
  id: "ID",
  image: "Image",
  invalidEmail: "Invalid Email",
  itemDeleted: "Item has been deleted",
  joiningDate: "Joining Date",
  joint: "Avoid for Joint",
  jointKey: "joint",
  jointList: "Joint List",
  level: "Level",
  levelOptions: [
    { value: "Beginner", label: "Beginner" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advanced", label: "Advanced" },
  ],
  limitCount: 10,
  listKey: "list",
  login: "Login",
  mechanics: "Mechanics",
  mechanicsJoint: "Joint",
  mechanicsJointKey: "mech-joint",
  mechanicsJointList: "Joint List",
  message: "Message",
  mode: "Mode",
  modeOptions: [
    { value: "Standard", label: "Standard" },
    { value: "Eccentric", label: "Eccentric" },
    { value: "Isokinetic", label: "Isokinetic" },
    { value: "Elastic", label: "Elastic" },
    { value: "Rowing", label: "Rowing" },
  ],
  movementPattern: "Movement Pattern",
  movementPatternKey: "move-pattern",
  movementPatternList: "Movement Pattern List",
  muscle: "Avoid for Muscle",
  muscleGroup: "Muscle Group",
  muscleKey: "muscle",
  muscleList: "Muscle List",
  na: "N/A",
  name: "Name",
  newSignups: "New Signups",
  noRevert: "You won't be able to revert this!",
  notification: "Notification",
  notificationKey: "notification",
  number: "Number",
  oops: "Oops!",
  other: "Other",
  paginationFalse: "pagination=false",
  password: "Password",
  passwordRequired: "Password must be 7 characters at minimum",
  passwordValidation:
    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
  required: "This field cannot be empty",
  position: "Position",
  primaryJoint: "Primary Joint",
  primaryJointKey: "primary-joint",
  primaryJointList: "Primary Joint List",
  primaryMuscle: "Primary Muscle",
  primaryMuscleKey: "primary-muscle",
  primaryMuscleList: "Primary Muscle List",
  privacyPolicy: "Privay Policy",
  privacyPolicyKey: "privacy-policy",
  programme: "Programme",
  programmeKey: "programme",
  programmeList: "Programme List",
  pyramidSet: "pyramidset",
  ques: "Question",
  recommendedWeight: "Recommended Weight",
  regression: "Regression",
  reports: "Reports",
  reportsKey: "reports",
  reps: "Reps",
  rest: "Rest (Seconds)",
  revenue: "Revenue",
  search: "Search",
  searchByEmail: "Search by Email, Username",
  searchByQues: "Search by Question",
  searchByName: "Search by Name",
  searchByTitle: "Search by Title",
  selectAll: "Select All",
  selectDate: "Select Date",
  selectTime: "Select Time",
  setRule: "Set Rule",
  setRuleOptions: [
    {
      value: "instant",
      label: "Instant",
    },
    {
      value: "scheduled",
      label: "Scheduled",
    },
  ],
  sets: "Sets",
  signout: "Sign out",
  startDate: "Start Date",
  status: {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  },
  statusKey: "Publish",
  submit: "Submit",
  subtitle: "Sub Title",
  subType: "Sub Type",
  success: "Success",
  superset: "superset",
  synergistMuscle: "Synergist Muscle",
  synergistMuscleKey: "synergist-muscle",
  synergistMuscleList: "Synergist Muscle List",
  tenure: "Tenure (in weeks)",
  tenureC: "Tenure",
  thumbnail: "Thumbnail",
  timeFormat: "hh:mm A",
  title: "Title",
  totalEnrolledUsers: "Total Enrolled Users",
  totalReps: "Total Reps",
  totalRepsSplit: "Reps Split by Body Part",
  totalRevenue: "Total Revenue",
  totalTransactions: "Total Transactions",
  totalWeightLifted: "Total Weight Lifted (KGS)",
  totalWeightLiftedSplit: "Weight Lifted split by Body Part (KGS)",
  totalWorkouts: "Total Workouts",
  triSet: "triset",
  tutorial: "Tutorial",
  tutorialKey: "tutorial",
  tutorialList: "Tutorial List",
  type: "Type",
  typeOptions: [
    {
      value: "WEIGHT",
      label: "Weight",
    },
    {
      value: "CALORIES",
      label: "Calories",
    },
    {
      value: "MINUTES",
      label: "Minutes",
    },
    {
      value: "DISTANCE",
      label: "Distance",
    },
  ],
  unit: "Unit",
  upload: "Upload",
  userAgreement: "User Agreement",
  userAgreementKey: "user-agreement",
  users: "Users",
  usersKey: "users",
  validEmailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  validPasswordRegex:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%#*?&]{7,}$/,
  value: "Value",
  valueOption: [
    { value: "LBS", label: "LBS" },
    { value: "KGS", label: "KGS" },
    { value: "KCAL", label: "KCAL" },
    { value: "UNIT", label: "UNIT" },
  ],
  video: "Video",
  view: "View",
  weight: "Weight",
  weightTypeKgs: "KGS",
  weightTypeLbs: "LBS",
  welcome: "Welcome",
  width: "Width",
  workout: "Workout",
  workoutKey: "workout",
  workoutList: "Workout List",
  youWantToSignOut: "You want to sign out?",
};
