import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const exercisecategoryAdd = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.EXERCISE_CATEGORY,
    data
  );
  return response;
};

export const exercisecategoryEdit = async (data) => {
  const response = await httpServiceInstance.patch(
    ApiUrl.EXERCISE_CATEGORY,
    data
  );
  return response;
};

export const exercisecategoryRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.EXERCISE_CATEGORY + `/${id}`
  );
  return response;
};

export const exercisecategory = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.EXERCISE_CATEGORY}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};
