import { types } from "../../Actions/types";

const initialState = {
  musclelist: [],
  error: null,
  muscleSingle: "",
  popupmodel: "",
};

const Muscle = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.MUSCLE_LIST:
      return {
        ...state,
        musclelist: payload,
        error,
      };
    case types.MUSCLE_SINGLE:
      return {
        ...state,
        muscleSingle: payload,
        error,
      };
    case types.MUSCLE_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default Muscle;
