import React from "react";
import AreaGraph from "../Common/Graphs/AreaGraph";
import LineGraph from "../Common/Graphs/LineGraph";
import { Constants } from "../Common/Constants";
import { useSelector } from "react-redux";

const Weekly = () => {
  const { userSingle } = useSelector((store) => store.Users);

  return (
    <div className="row mt-4">
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={userSingle?.totalWorkoutCreatedByyearly}
          title={Constants.totalWorkouts}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={userSingle?.totalReps}
          title={Constants.totalReps}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={userSingle?.totalWeighLifted}
          title={Constants.totalWeightLifted}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <LineGraph
          data={userSingle?.totalWeightByCategoryWithDetails}
          title={Constants.totalWeightLiftedSplit}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <LineGraph
          data={userSingle?.totalRepsByCategoryWithDetails}
          title={Constants.totalRepsSplit}
          format=""
        />
      </div>
      {/* <div id="chart" className="col-md-4">
        <LineGraph
          data={userSingle?.data?.data?.course_wise}
          title={Constants.course}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <BarGraph
          data={userSingle?.data?.data?.referred_users_data}
          title={Constants.referredUsers}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <BarGraph
          data={userSingle?.data?.data?.referred_amount_data}
          title={Constants.referAmount}
          format={Constants.currency}
        />
      </div>
      <div id="chart" className="col-md-4">
        <BarGraph
          data={userSingle?.data?.data?.total_uninstalls}
          title={Constants.uninstalls}
          format=""
        />
      </div> */}
      <div id="html-dist"></div>
    </div>
  );
};

export default Weekly;
