import { Form, Formik } from "formik";
import { Constants } from "../Common/Constants";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import TextEditor from "../Common/Fields/TextEditor";
import PageHeader from "../Common/PageHeader";
import * as Yup from "yup";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

const section = { type: Constants.privacyPolicyKey };

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const [initialValues, setInitialValues] = useState({
    policy: "",
  });

  // Validation for privacy form
  const validationSchema = Yup.object().shape({
    policy: Yup.string().required(Constants.required),
  });

  // get admin confg data
  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response?.data?.data);
    });
  }, []);

  // pre fill privacy policy form
  useEffect(() => {
    if (settings?.items?.description) {
      setInitialValues({ policy: settings?.items?.description });
    }
  }, [settings]);

  // Handle editor change
  const onEditorChange = (editor, value, setFieldValue) => {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  return (
    <>
      <div className=" container-xxl">
        <PageHeader pagetitle={Constants.privacyPolicy} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const dataForm = {};
            dataForm.description = values?.policy;
            dataForm.type = section.type;
            // Add privacy policy API
            const submitFunc = addAdminConfigurations(dataForm);
            dispatch(submitFunc).then((response) => {
              if (response?.error) {
                dispatch({
                  type: types.ERROR_ALERT_VISIBLE,
                  payload: response?.error,
                });
              } else {
                dispatch({
                  type: types.SUCCESS_ALERT_VISIBLE,
                  payload: response?.data?.message,
                });
                dispatch(getAdminConfigurations(section));
              }
            });
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className="list-view card">
              <div className="mx-4">
                <TextEditor
                  hideRequiredIcon="true"
                  onChange={(_event, editor) =>
                    onEditorChange(editor, "policy", setFieldValue)
                  }
                  errors={errors?.policy ? errors?.policy : undefined}
                  touched={touched?.policy}
                  data={`${values?.policy || ""}`}
                />
              </div>
              <ButtonCustom label={Constants.submit} type="submit" />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PrivacyPolicy;
