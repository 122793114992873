import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Constants } from "../Common/Constants";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Quarterly from "./Quarterly";
import Yearly from "./Yearly";
import TabsBootstrap from "../Common/UiModel/TabsBootstrap";
import { useDispatch } from "react-redux";
import { getUserDetail } from "../../Redux/Actions";

const Analytics = () => {
  const tabsList = [
    {
      eventKey: Constants.analyticsWeek,
      title: Constants.analyticsSevenDays,
      component: <Weekly />,
    },
    {
      eventKey: Constants.analyticsMonth,
      title: Constants.analyticsThirtyDays,
      component: <Monthly />,
    },
    {
      eventKey: Constants.analyticsThreeMonths,
      title: Constants.analyticsNinetyDays,
      component: <Quarterly />,
    },
    {
      eventKey: Constants.analyticsYear,
      title: Constants.analyticsOneYear,
      component: <Yearly />,
    },
  ];
  const [activetab, setActiveTab] = useState(tabsList?.[0]?.eventKey);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetail(id, activetab));
  }, [activetab]);

  useEffect(() => {
    // Set active tab as per URL
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    // Set URL in correspondance with active tab
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className="row catalog_box al_catalog_box mt-4">
      <div className="col-md-12 mb-4 order-list-view">
        {tabsList.length > 0 && (
          <TabsBootstrap
            defaultActiveKey={activetab}
            onSelect={(index) => {
              setActiveTab(index);
            }}
            activeKey={activetab}
            handleTabSelect={handleTabSelect}
            options={tabsList}
          />
        )}
      </div>
    </div>
  );
};

export default Analytics;
