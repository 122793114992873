import { types } from "../../Actions/types";

const initialState = {
  synergistmusclelist: [],
  error: null,
  synergistMuscleSingle: "",
  popupmodel: "",
};

const SynergistMuscle = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.SYNERGIST_MUSCLE_LIST:
      return {
        ...state,
        synergistmusclelist: payload,
        error,
      };
    case types.SYNERGIST_MUSCLE_SINGLE:
      return {
        ...state,
        synergistMuscleSingle: payload,
        error,
      };
    case types.SYNERGIST_MUSCLE_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default SynergistMuscle;
