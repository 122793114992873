import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { workoutInsert, workoutList, workoutUpdate } from "../../Redux/Actions";
import TextArea from "../Common/Fields/TextArea";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import MultipleSelect from "../Exercise/MultipleSelect";

const WorkoutForm = () => {
  const dispatch = useDispatch();
  const { workoutSingle } = useSelector((state) => state.Workout);
  const { goalslist } = useSelector((state) => state.Goals);
  const [selectedGoals, setSelectedGoals] = useState([]);
  // Initial values to Workout form
  const [initialValues, setInitialValues] = useState({
    workoutId: "",
    name: "",
    description: "",
    thumbnail: "",
    goals: [],
  });

  // Validations for Workout form
  const validationSchema = Yup.object().shape({
    workoutId: Yup.string().required(Constants.required),
    name: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    thumbnail: Yup.string().required(Constants.required),
    goals: Yup.array().min(1, Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill Workout form
    if (workoutSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (workoutSingle[key]) {
          editValue[key] = workoutSingle[key];
          if (key === "goals") {
            editValue[key] = workoutSingle?.[key]?.map((elt) => elt?._id);
            setSelectedGoals(
              workoutSingle?.[key]?.map((elt) => ({
                value: elt?._id,
                label: elt?.title,
              }))
            );
          }
        }
        setInitialValues(editValue);
      }
    }
  }, [workoutSingle]);

  const handleFileChange = (file, setFieldValue, field) => {
    // Add  thumbnail for programme
    setFieldValue(field, file?.[0]);
  };

  const onMultipleSelectChange = (e, key, setKey) => {
    setSelectedGoals(e);
    setKey(
      key,
      e?.map((elt) => elt?.value)
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update Workout API
        let submitFunc = workoutInsert(values);
        if (workoutSingle._id) {
          values.id = workoutSingle._id;
          submitFunc = workoutUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.WORKOUT_SINGLE, payload: "" });
            dispatch({ type: types.WORKOUT_FORM_MODEL, payload: "" });
          }
          dispatch(workoutList());
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="workoutId"
              className="form-control"
              errors={touched?.workoutId ? errors?.workoutId : undefined}
              touched={touched?.workoutId}
              label={Constants.id}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.thumbnail}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.thumbnail && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="381"
                    height="426"
                    errors={touched?.thumbnail ? errors?.thumbnail : undefined}
                    touched={touched?.thumbnail}
                    label={`${Constants.upload} ${Constants.thumbnail}`}
                    handleFile={(files) =>
                      handleFileChange(files, setFieldValue, "thumbnail")
                    }
                    maxFiles={1}
                  />
                )}
              </div>
              <div className="after-up">
                {values && values?.thumbnail && (
                  <ViewImageFile
                    file={values?.thumbnail}
                    onClick={() => setFieldValue("thumbnail", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <MultipleSelect
              label={`${Constants.goals}`}
              required={true}
              options={goalslist?.goals || []}
              onChange={(e) =>
                onMultipleSelectChange(e, "goals", setFieldValue)
              }
              value={selectedGoals}
              errors={touched?.goals ? errors?.goals : undefined}
              touched={touched?.goals}
            />
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default WorkoutForm;
