import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const exerciseAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.EXERCISE, data);
  return response;
};

export const exerciseEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.EXERCISE, data);
  return response;
};

export const exerciseRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.EXERCISE + `/${id}`);
  return response;
};

export const exercise = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.EXERCISE}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};
