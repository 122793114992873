import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import {
  challengeInsert,
  challengeList,
  challengeUpdate,
} from "../../Redux/Actions";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import TextArea from "../Common/Fields/TextArea";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import SingleSelect from "../Exercise/SingleSelect";
import ReactSelect from "../Common/Fields/ReactSelect";

const ChallengeForm = () => {
  const today = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const { challengeSingle } = useSelector((state) => state.Challenge);
  const { exerciseCategorylist } = useSelector(
    (state) => state.ExerciseCategory
  );

  const [selectedValues, setSelectedValues] = useState({
    mode: "",
    activityType: "",
    value: "",
    bodyPartSpecificId: "",
  });
  // Initial values to challenge
  const [initialValues, setInitialValues] = useState({
    name: "",
    unit: "",
    value: "",
    description: "",
    startDate: "",
    activityType: "",
    endDate: "",
    // it is basically exercise category ID
    bodyPartSpecificId: "",
    mode: "",
    image: "",
  });

  // Validations for challenge
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    unit: Yup.number().required(Constants.required),
    value: Yup.string().required(Constants.required),
    startDate: Yup.string().required(Constants.required),
    endDate: Yup.string().required(Constants.required),
    image: Yup.string().required(Constants.required),
    activityType: Yup.string().required(Constants.required),
    bodyPartSpecificId: Yup.string().required(Constants.required),
    mode: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill challenge
    if (challengeSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (challengeSingle[key]) {
          editValue[key] = challengeSingle[key];
          if (key === "mode") {
            setSelectedValues((prev) => ({
              ...prev,
              mode: Constants.modeOptions?.find(
                (elt) => elt.value === challengeSingle[key]
              ),
            }));
          }
          if (key === "value") {
            setSelectedValues((prev) => ({
              ...prev,
              value: Constants.valueOption?.find(
                (elt) => elt.value === challengeSingle[key]
              ),
            }));
          }
          if (key === "activityType") {
            setSelectedValues((prev) => ({
              ...prev,
              activityType: Constants.activityTypeOptions?.find(
                (elt) => elt.value === challengeSingle[key]
              ),
            }));
          }
          if (key === "bodyPartSpecificId") {
            editValue[key] = challengeSingle[key]?._id;
            setSelectedValues((prev) => ({
              ...prev,
              bodyPartSpecificId: {
                label: challengeSingle[key]?.title,
                value: challengeSingle[key]?._id,
              },
            }));
          }
        }
        setInitialValues(editValue);
      }
    }
  }, [challengeSingle, exerciseCategorylist]);

  const handleFileChange = (file, setFieldValue, field) => {
    // Add  image for challenge
    setFieldValue(field, file?.[0]);
  };

  const onSingleSelectChange = (e, key, setKey) => {
    setSelectedValues((prev) => ({ ...prev, [key]: e }));
    setKey(key, e.value);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update challenge API
        let submitFunc = challengeInsert(values);
        if (challengeSingle._id) {
          values.id = challengeSingle._id;
          submitFunc = challengeUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.CHALLENGE_SINGLE, payload: "" });
            dispatch({ type: types.CHALLENGE_FORM_MODEL, payload: "" });
          }
          dispatch(challengeList());
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-4 mb-2">
            <TextField
              type="number"
              name="unit"
              className="form-control"
              errors={touched?.unit ? errors?.unit : undefined}
              touched={touched?.unit}
              label={Constants.unit}
            />
          </div>
          <div className="col-md-4 mb-2">
            <ReactSelect
              label={`${Constants.value}`}
              required={true}
              options={Constants.valueOption}
              onChange={(e) => onSingleSelectChange(e, "value", setFieldValue)}
              value={selectedValues.value}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="string"
              notrequired="true"
              name="description"
              className="form-control"
              label={Constants.description}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="date"
              name="startDate"
              min={today}
              className="form-control"
              errors={touched?.startDate ? errors?.startDate : undefined}
              touched={touched?.startDate}
              label={Constants.startDate}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="date"
              name="endDate"
              min={values?.startDate}
              className="form-control"
              errors={touched?.endDate ? errors?.endDate : undefined}
              touched={touched?.endDate}
              label={Constants.endDate}
            />
          </div>
          <div className="col-md-6 mb-2">
            <SingleSelect
              label={`${Constants.bodyPartSpecific}`}
              required={true}
              options={exerciseCategorylist?.exerciseCategory || []}
              onChange={(e) =>
                onSingleSelectChange(e, "bodyPartSpecificId", setFieldValue)
              }
              value={selectedValues.bodyPartSpecificId}
              errors={
                touched?.bodyPartSpecificId
                  ? errors?.bodyPartSpecificId
                  : undefined
              }
              touched={touched?.bodyPartSpecificId}
            />
          </div>
          <div className="col-md-6 mb-2">
            <ReactSelect
              label={`${Constants.mode}`}
              required={true}
              options={Constants.modeOptions}
              onChange={(e) => onSingleSelectChange(e, "mode", setFieldValue)}
              value={selectedValues.mode}
            />
          </div>
          <div className="col-md-6 mb-2">
            <ReactSelect
              label={`${Constants.activityType}`}
              required={true}
              options={Constants.activityTypeOptions}
              onChange={(e) =>
                onSingleSelectChange(e, "activityType", setFieldValue)
              }
              value={selectedValues.activityType}
            />
          </div>
          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.image}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.image && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="381"
                    height="426"
                    errors={touched?.image ? errors?.image : undefined}
                    touched={touched?.image}
                    label={`${Constants.upload} ${Constants.image}`}
                    handleFile={(files) =>
                      handleFileChange(files, setFieldValue, "image")
                    }
                    maxFiles={1}
                  />
                )}
              </div>
              <div className="after-up">
                {values && values?.image && (
                  <ViewImageFile
                    file={values?.image}
                    onClick={() => setFieldValue("image", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default ChallengeForm;
