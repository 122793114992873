import { Constants } from "../Common/Constants";

const getValueLabelObject = (data, single = false) => {
  if (!data) return "";
  if (single) {
    return {
      value: data?._id,
      label: data?.name || data?.title,
    };
  }
  return data.map((elt) => ({
    value: elt?._id,
    label: elt?.name,
  }));
};

export const preFilledValues = (exerciseSingle) => {
  if (!exerciseSingle) return {};
  return {
    level: Constants.levelOptions.find(
      (elt) => elt.value === exerciseSingle.level
    ),
    exerciseCategoryId: getValueLabelObject(
      exerciseSingle.exerciseCategoryData,
      true
    ),
    exerciseGoal: getValueLabelObject(exerciseSingle.exerciseGoalData, true),
    exercisePosition: getValueLabelObject(
      exerciseSingle.exercisePositionData,
      true
    ),
    mechanics: getValueLabelObject(exerciseSingle.mechanicsData, true),
    movementPatterns: getValueLabelObject(
      exerciseSingle.movementPatternsData,
      true
    ),
    force: getValueLabelObject(exerciseSingle.forceData, true),
    avoidForJoint: getValueLabelObject(exerciseSingle.avoidForJointsData),
    avoidForMuscle: getValueLabelObject(exerciseSingle.avoidForMusclesData),
    equipmentTraditional: getValueLabelObject(exerciseSingle.equipmentData),
    bodyGroup: getValueLabelObject(exerciseSingle.bodyGroupData),
    primaryJoint: getValueLabelObject(exerciseSingle.primaryJointsData),
    primarymuscles: getValueLabelObject(exerciseSingle.primaryMusclesData),
    synergistmuscle: getValueLabelObject(exerciseSingle.synergistMusclesData),
  };
};
