import axios from "axios";
import { Constants } from "../components/Common/Constants";

class HttpService {
  defaultOptions = () => {
    return {
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(Constants.accessToken)}`,
      },
    };
  };

  defaultOptionsFormdata = () => {
    return {
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(Constants.accessToken)}`,
        "Content-Type": "multipart/form-data",
      },
    };
  };

  successResult = (res) => {
    if (res.data && res.data.status === true) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: Constants.somethingWentWrong };
    }
  };

  failureResult = (err) => {
    if (err?.response?.status === 401) {
      localStorage.clear();
      window.location = "/sign-in";
    }
    const data = { status: false };
    try {
      data.error = err.message;
      if (err.response && err.response.data) {
        data.error = err.response.data.error;
      }
      return data;
    } catch (error) {
      data.error = error.message;
      return data;
    }
  };

  get = async (url, options = {}) => {
    try {
      const res = await axios.get(url, {
        ...this.defaultOptions(),
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };

  post = async (url, data, options = {}) => {
    try {
      const res = await axios.post(url, data, {
        ...this.defaultOptions(),
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };

  postForm = async (url, data, options = {}) => {
    try {
      const res = await axios.post(url, data, {
        ...this.defaultOptionsFormdata(),
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };

  patch = async (url, data, options = {}) => {
    try {
      const res = await axios.patch(url, data, {
        ...this.defaultOptions(),
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };

  delete = async (url, data, options = {}) => {
    try {
      const res = await axios.delete(url, {
        ...this.defaultOptions(),
        data,
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };
}

const httpServiceInstance = new HttpService();

export default httpServiceInstance;
