import React from "react";

// ButtonCustom component receives props
const ButtonCustom = (props) => {
  let className = props.className;
  let type = props.type;

  // If className prop is not provided, default to a primary button style
  if (!className) {
    className = "btn btn-primary text-uppercase px-4 fw-bold m-3 p-3";
  }

  // If type prop is not provided, default to "submit"
  if (!type) {
    type = "submit";
  }

  return (
    <div className="col-12 text-center my-2 mt-4 mb-4 btn-hover cursor-pointer">
      <button {...props} className={className} type={type}>
        {props.label}
      </button>
    </div>
  );
};

export default ButtonCustom;
