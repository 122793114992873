import { types } from "../Actions/types";

const initialState = {
  equipmentlist: [],
  error: null,
  equipmentSingle: "",
  popupmodel: "",
};

const Equipment = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.EQUIPMENT_LIST:
      return {
        ...state,
        equipmentlist: payload,
        error,
      };
    case types.EQUIPMENT_SINGLE:
      return {
        ...state,
        equipmentSingle: payload,
        error,
      };
    case types.EQUIPMENT_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default Equipment;
