import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  dayWorkoutDelete,
  dayWorkoutInsert,
  getProgrammeDetail,
  workoutList,
} from "../../../Redux/Actions";
import { useSelector } from "react-redux";
import { Constants } from "../../Common/Constants";
import DraggableWorkout from "./DraggableWrokout";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DroppableDay from "./DroppableDay";
import { types } from "../../../Redux/Actions/types";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";

const ProgrammeWorkoutDetail = () => {
  const dispatch = useDispatch();
  const { programmeSingle } = useSelector((state) => state.Programme);
  const { id } = useParams();
  const [weekList, setWeekList] = useState([]);
  const [activeWeek, setActiveWeek] = useState(null);
  const { workoutlist } = useSelector((state) => state.Workout);
  const [workoutData, setWorkoutData] = useState([]);
  const [view, setView] = useState(Constants.gridKey);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setWorkoutData(workoutlist?.items);
  }, [workoutlist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) dispatch(workoutList({ skipPagination: true, search }));
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (programmeSingle?.weekData) setWeekList(programmeSingle?.weekData);
    setActiveWeek(programmeSingle?.weekData?.[0]);
  }, [programmeSingle]);

  const handleDrop = (item, day, week) => {
    const data = { week: week?._id, workout_id: item?.id, day: day?.day };
    dispatch(dayWorkoutInsert(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response.message,
        });
      }
      dispatch(getProgrammeDetail(id));
    });
  };

  const handleDelete = (day, week) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(dayWorkoutDelete(week?._id, day?.day)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(getProgrammeDetail(id));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="row">
        <div className="col-lg-8">
          <div className="accordion accordian_custom" id="accordionExample">
            {weekList?.map((week) => (
              <div className="accordion-item" key={week._id}>
                <h2 className="accordion-header" id={`heading${week.weekno}`}>
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    aria-expanded={activeWeek?.weekno === week.weekno}
                    onClick={() =>
                      setActiveWeek(
                        activeWeek?.weekno === week?.weekno ? null : week
                      )
                    }
                    aria-controls={`collapse${week.weekno}`}
                  >
                    {`Week ${week.weekno}`}
                  </button>
                </h2>
                <div
                  id={`collapse${week.weekno}`}
                  className={`accordion-collapse collapse ${
                    activeWeek?.weekno === week.weekno ? "show" : ""
                  }`}
                  aria-labelledby={`heading${week.weekno}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {week?.weekDetails?.map((day) => (
                      <DroppableDay
                        key={day?.day}
                        day={day}
                        onDrop={(item) => handleDrop(item, day, week)}
                        deleteWorkout={(day) => handleDelete(day, week)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className={`card_exercise ${
              view === Constants.gridKey ? "toggle_exercise" : ""
            }`}
          >
            <div className="search_sec">
              <input
                type="search"
                className="form-control"
                onChange={(e) => {
                  if (e.target.value) setSearch(e.target.value);
                  else dispatch(workoutList({ skipPagination: true }));
                }}
                placeholder="Search for workout"
              />
              <i className="fa fa-search search_pos" aria-hidden="true"></i>
            </div>
            <div className="head_exercise">
              <h3>{Constants.workout}</h3>
              <ul className="nav nav_exercise">
                <li onClick={() => setView(Constants.gridKey)}>
                  <i
                    className="fa fa-th-large cursor-pointer"
                    aria-hidden="true"
                  ></i>
                </li>
                <li onClick={() => setView(Constants.listKey)}>
                  <i
                    className="fa fa-list cursor-pointer"
                    aria-hidden="true"
                  ></i>
                </li>
              </ul>
            </div>
            <div className="list-scroll" id="style-2">
              {workoutData?.map((item) => (
                <DraggableWorkout key={item._id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default ProgrammeWorkoutDetail;
