import { types } from "../Actions/types";

const initialState = {
  challengelist: [],
  challengeuserlist: [],
  error: null,
  challengeSingle: "",
  popupmodel: "",
};

const Challenge = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.CHALLENGE_LIST:
      return {
        ...state,
        challengelist: payload,
        error,
      };
    case types.CHALLENGE_SINGLE:
      return {
        ...state,
        challengeSingle: payload,
        error,
      };
    case types.CHALLENGE_USER_LIST:
      return {
        ...state,
        challengeuserlist: payload,
        error,
      };
    case types.CHALLENGE_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default Challenge;
