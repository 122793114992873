import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const movementPatternAdd = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.MOVEMENT_PATTERN,
    data
  );
  return response;
};

export const movementPatternEdit = async (data) => {
  const response = await httpServiceInstance.patch(
    ApiUrl.MOVEMENT_PATTERN,
    data
  );
  return response;
};

export const movementPatternRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.MOVEMENT_PATTERN + `/${id}`
  );
  return response;
};

export const movementPattern = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.MOVEMENT_PATTERN}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};
