import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import { RoutesUrl } from "../../Common/RoutesUrl";
import TabsBootstrap from "../../Common/UiModel/TabsBootstrap";
import {
  getProgrammeDetail,
  programmeUsers,
  workoutList,
} from "../../../Redux/Actions";
import ProgrammeWorkoutDetail from "../WorkoutAssign";
import ProgrammeDetailPage from "./ProgrammeDetailPage";
import ProgrammeResponsePage from "./ProgrammeResponsePage";

const ProgrammeDetail = () => {
  // Tabs initially
  const initialTabsList = [
    {
      eventKey: "detail",
      title: Constants.detail,
      component: <ProgrammeDetailPage />,
    },
    {
      eventKey: "workout",
      title: Constants.workout,
      component: <ProgrammeWorkoutDetail />,
    },
    {
      eventKey: "challenge",
      title: Constants.users,
      component: <ProgrammeResponsePage />,
    },
  ];
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const activeTab = params.get("activeTab");
  const [tabsList] = useState(initialTabsList);
  const [activetab, setActiveTab] = useState(
    activeTab || tabsList?.[0]?.eventKey
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getProgrammeDetail(id));
    dispatch(workoutList({ skipPagination: true }));
    dispatch(programmeUsers({ id }));
  }, []);

  useEffect(() => {
    // Set active tab as per URL
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    // Set URL in correspondance with active tab
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className="container-xxl common-space">
      <PageHeader list={RoutesUrl?.programme} />
      <div className="row catalog_box al_catalog_box">
        <div className="col-md-12 mb-4 order-list-view">
          {tabsList.length > 0 && (
            <TabsBootstrap
              defaultActiveKey={activetab}
              onSelect={(index) => {
                setActiveTab(index);
              }}
              activeKey={activetab}
              handleTabSelect={handleTabSelect}
              options={tabsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgrammeDetail;
