import React, { useEffect, useState } from "react";
import ReactSelect from "../Common/Fields/ReactSelect";

const SingleSelect = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props?.options?.length > 0) {
      setOptions(
        props.options?.map((elt) => ({
          value: elt._id,
          label: elt?.title || elt?.name,
        }))
      );
    }
  }, [props.options]);

  return (
    <ReactSelect
      label={props.label}
      required={props.required}
      options={options}
      onChange={(e) => props.onChange(e)}
      value={props.value}
      errors={props?.errors}
      touched={props?.touched}
    />
  );
};

export default SingleSelect;
