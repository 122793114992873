import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const tutorialAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.TUTORIAL, data);
  return response;
};

export const tutorialEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.TUTORIAL, data);
  return response;
};

export const tutorialRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.TUTORIAL + `/${id}`);
  return response;
};

export const tutorial = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.TUTORIAL}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};
