import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggabelItem";
import DroppingArea from "./DroppingArea";
import { Constants } from "../../Common/Constants";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../Redux/Actions/types";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import { useParams } from "react-router-dom";
import {
  equipmentList,
  forceList,
  getWorkoutDetail,
  primaryMuscleList,
  workoutExerciseInsert,
} from "../../../Redux/Actions";
import WorkoutExerciseForm from "./WorkoutExerciseForm";
import FilterExercises from "./FilterExercises";
import { Modal } from "react-bootstrap";
import LoaderSpinner from "../../../helper/LoaderSpinner";

const initialFilterState = {
  primaryMuscles: [],
  equipment: [],
  force: [],
};

const AddExercise = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { workoutdetail, workoutpopupmodel } = useSelector(
    (state) => state.Workout
  );
  const [assignedExer, setAssignedExer] = useState([]);
  const [unassignedExer, setUnassignedExer] = useState([]);
  const [view, setView] = useState(Constants.gridKey);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getDetail();
    dispatch(primaryMuscleList({ skipPagination: true }));
    dispatch(forceList({ skipPagination: true }));
    dispatch(equipmentList({ skipPagination: true }));
  }, []);

  const getDetail = async () => {
    setLoader(true);
    await dispatch(getWorkoutDetail({ id }));
    setLoader(false);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // De-bounce function for search with delay of 500 seconds
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getWorkoutDetail({ id, search, ...selectedFilters }));
      }, 500);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [search]);

  useEffect(() => {
    if (workoutdetail?.unassignedExercises) {
      setUnassignedExer(workoutdetail?.unassignedExercises);
      setAssignedExer(workoutdetail?.workoutexerciseDetail?.exercises);
    }
  }, [workoutdetail]);

  const handleDrop = async (item) => {
    const resp = await dispatch(
      workoutExerciseInsert({
        exerciseId: item?._id,
        id,
        type: Constants.exerciseKey,
      })
    );
    if (resp?.data?.setDetail?._id) {
      dispatch(getWorkoutDetail({ id }));
      dispatch({
        type: types.WORKOUT_ADD_EXER_FORM_MODEL,
        payload: {
          msg: Constants.addExercise,
          show: true,
          setId: resp?.data?.setDetail?._id,
          type: Constants.exerciseKey,
        },
      });
    }
  };

  const handleApply = (filters) => {
    setSelectedFilters(filters);
    getFilteredExercises(filters);
  };

  const handleClearAll = () => {
    setSelectedFilters(initialFilterState);
    getFilteredExercises(initialFilterState);
  };

  const getFilteredExercises = (filters) => {
    dispatch(getWorkoutDetail({ id, search, ...filters }));
    setShowFilterModal(false);
  };

  return (
    <>
      {loader && <LoaderSpinner />}
      <DndProvider backend={HTML5Backend}>
        <div className="container-xxl">
          <div className="row">
            <div className="col-md-6">
              <div className="card_exercise pad_exercise">
                <DroppingArea droppedItems={assignedExer} onDrop={handleDrop} />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`card_exercise ${
                  view === Constants.gridKey ? "toggle_exercise" : ""
                }`}
              >
                <div className="search_sec">
                  <input
                    type="search"
                    className="form-control"
                    onChange={(e) => {
                      if (e?.target?.value) setSearch(e.target.value);
                      else
                        dispatch(getWorkoutDetail({ id, ...selectedFilters }));
                    }}
                    placeholder="Search for an exercise"
                  />
                  <i className="fa fa-search search_pos" aria-hidden="true"></i>
                </div>
                <div className="head_exercise">
                  <h3>{Constants.exercise}</h3>
                  <ul className="nav nav_exercise">
                    <li>
                      <div
                        className="filer_exercise cursor-pointer"
                        onClick={() => setShowFilterModal(true)}
                      >
                        Filter By
                        <i className="fa fa-filter ps-2" aria-hidden="true"></i>
                      </div>
                    </li>
                    <li onClick={() => setView(Constants.gridKey)}>
                      <i
                        className="fa fa-th-large cursor-pointer"
                        aria-hidden="true"
                      ></i>
                    </li>
                    <li onClick={() => setView(Constants.listKey)}>
                      <i
                        className="fa fa-list cursor-pointer"
                        aria-hidden="true"
                      ></i>
                    </li>
                  </ul>
                </div>
                <div className="list-scroll" id="style-2">
                  {unassignedExer.map((item) => (
                    <DraggableItem key={item._id} item={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
      <ModalPopup
        show={workoutpopupmodel}
        onHide={() => {
          dispatch({ type: types.WORKOUT_ADD_EXER_SINGLE, payload: "" });
          dispatch({ type: types.WORKOUT_ADD_EXER_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={workoutpopupmodel.msg}
        component={<WorkoutExerciseForm />}
      />
      {/* New Modal for Filter */}
      <Modal
        show={showFilterModal}
        className="modal-md modal-popup modal-md"
        onHide={() => setShowFilterModal(false)}
      >
        <div className="workout_modal">
          <Modal.Header className="modal-header modal-pad">
            <div className="flex_head">
              <h5 className="modal-title fw-bold" id="expeditLabel">
                Filter Exercises
              </h5>
              <button className="clear_all" onClick={handleClearAll}>
                Clear All
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="modal-body p-0">
            <div className="deadline-form">
              <FilterExercises
                selectedFilters={selectedFilters}
                applyFilters={handleApply}
                onHide={() => {
                  setShowFilterModal(false);
                  setSelectedFilters(initialFilterState);
                }}
              />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AddExercise;
