import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../helper/HttpService";
import { Constants } from "../components/Common/Constants";

export const users = async (data) => {
  const url = `${ApiUrl.USER_LIST}${
    data?.skipPagination
      ? `?${Constants.paginationFalse}`
      : `?skip=${data?.skip || 0}&limit=${data?.limit || Constants.limitCount}`
  }${data && data?.search ? `&search=${data?.search}` : ""}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const fetchUserDetail = async (userId, days) => {
  const response = await httpServiceInstance.get(
    ApiUrl.USER_DETAIL + `?userId=${userId}&days=${days}`
  );
  return response;
};
