import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const goalTimingAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.GOAL_TIMING, data);
  return response;
};

export const goalTimingEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.GOAL_TIMING, data);
  return response;
};

export const goalTiming = async () => {
  const response = await httpServiceInstance.get(`${ApiUrl.GOAL_TIMING}`);
  return response;
};

export const getConfigurations = async (data) => {
  const url = ApiUrl.CONFIGURATION + "?type=" + data.type;
  const res = await httpServiceInstance.get(url);
  return res;
};

export const addConfigurations = async (data) => {
  const url = ApiUrl.CONFIGURATION;
  const res = await httpServiceInstance.post(url, data);
  return res;
};

export const getImageUrl = async (formData) => {
  const url = ApiUrl.IMAGE_URL;
  const response = await httpServiceInstance.postForm(url, formData);
  return response;
};

export const faqAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.FAQ, data);
  return response;
};

export const faqEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.FAQ, data);
  return response;
};

export const faqRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.FAQ + `/${id}`);
  return response;
};

export const faq = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.FAQ}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};
