/* eslint-disable space-before-function-paren */
import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RequiredIcon from "./RequiredIcon";
import { getImageUrl } from "../../../services/cms.service";

const handleImageUpload = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await getImageUrl(formData);
  return {
    default: response.data.data,
  };
};

class CustomUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    const file = await this.loader.file;
    const uploadResponse = await handleImageUpload(file);
    return uploadResponse;
  }
}

function customUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new CustomUploadAdapter(loader);
  };
}

const TextEditor = (props) => {
  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "insertImage",
    ],
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
    },
    extraPlugins: [customUploadAdapterPlugin],
  };

  return (
    <div>
      <label className="form-label">{props?.label}</label>
      {props.hideRequiredIcon ? null : <RequiredIcon />}
      <CKEditor
        editor={ClassicEditor}
        config={props?.default ? {} : editorConfiguration}
        onChange={props?.onChange}
        data={props.data}
      />
      {(props.errors || props.touched) && (
        <p className="error-msg">{props.errors}</p>
      )}
    </div>
  );
};

export default TextEditor;
