import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Constants } from "../../Common/Constants";
import { faqInsert, faqList, faqUpdate } from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import TextField from "../../Common/Fields/TextField";
import TextEditor from "../../Common/Fields/TextEditor";
import ButtonCustom from "../../Common/Fields/ButtonCustom";

const FaqForm = () => {
  const dispatch = useDispatch();
  const { faqSingle } = useSelector((state) => state.Cms);
  const [initialValues, setInitialValues] = useState({
    ques: "",
    ans: "",
  });

  // Validation schema for faq form
  const validationSchema = Yup.object().shape({
    ques: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidWordLimitRegex, Constants.invalidWordLimit),
    ans: Yup.string().required(Constants.required),
  });

  // Handle editor change
  const onEditorChange = function (editor, value, setFieldValue) {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  // pre fill the faq form
  useEffect(() => {
    if (faqSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (faqSingle[key]) {
          editValue[key] = faqSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [faqSingle]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // faq add odd or insert API
        let submitFunc = faqInsert(values);
        if (faqSingle?._id) {
          values.id = faqSingle?._id;
          submitFunc = faqUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch(faqList());
            dispatch({ type: types.FAQ_SINGLE, payload: "" });
            dispatch({ type: types.FAQ_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-md-12">
              <TextField
                type="text"
                name="ques"
                className="form-control"
                errors={touched?.ques ? errors?.ques : undefined}
                touched={touched?.ques}
                label={Constants.ques}
              />
            </div>
            <div className="col-md-12">
              <TextEditor
                label={Constants.ans}
                onChange={(_event, editor) =>
                  onEditorChange(editor, "ans", setFieldValue)
                }
                data={`${values?.ans || ""}`}
                errors={touched?.ans ? errors?.ans : undefined}
                touched={touched?.ans}
              />
            </div>
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default FaqForm;
