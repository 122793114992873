import React from "react";
import { useSelector } from "react-redux";
import CardBlock from "./CardBlock";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import Analytics from "./Analytics";
import dayjs from "dayjs";

const UserDetail = () => {
  const { userSingle } = useSelector((store) => store.Users);

  return (
    <div className="container-xxl mb-4">
      <PageHeader pagetitle={userSingle?.user?.name || Constants.na} />
      {/* Personal Details */}
      <CardBlock user={userSingle?.user} />
      <div className="row">
        {/* Goals */}
        <div className="col-md-6 d-flex">
          <div className="card mt-4 border-shadow w-100 card-clr card-red card-user-details">
            <h4>{`${Constants.goals}`}</h4>
            <div className="h5 line-b">
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Type : ${userSingle?.userGoalWithExerciseDetails?.[0]?.type}`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Durations : ${userSingle?.userGoalWithExerciseDetails?.[0]?.duration}`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Start Date :  ${dayjs(
                  userSingle?.userGoalWithExerciseDetails?.[0]?.startDate
                ).format(Constants.dateFormat)} at ${dayjs(
                  userSingle?.userGoalWithExerciseDetails?.[0]?.startDate
                ).format(Constants.timeFormat)}`}</div>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="card mt-4 border-shadow w-100 card-clr card-user-details">
            <h4>{Constants.other}</h4>
            <div className="h5 line-b">
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Last Active :  ${dayjs(
                  userSingle?.userGoalWithExerciseDetails?.[0]?.startDate
                ).format(Constants.dateFormat)} at ${dayjs(
                  userSingle?.userGoalWithExerciseDetails?.[0]?.startDate
                ).format(Constants.timeFormat)}`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Avg Workouts / week : ${
                  userSingle?.userGoalWithExerciseDetails?.[0]
                    ?.averageWorkout || 0
                }`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Challenges attempted : ${
                  userSingle?.userGoalWithExerciseDetails?.[0]
                    ?.challengeCount || 0
                }`}</div>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Analytics />
    </div>
  );
};

export default UserDetail;
