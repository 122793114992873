export const types = {
  ADMIN_CONFIGURATION_ADD: "ADMIN_CONFIGURATION_ADD",
  ADMIN_CONFIGURATION_LIST: "ADMIN_CONFIGURATION_LIST",
  AWS_ACL: "public-read",
  AWS_REGION: "ap-south-1",
  AWS_S3_BUCKET: "",
  AWS_S3URL: "",
  BADGE_FORM_MODEL: "BADGE_FORM_MODEL",
  BADGE_LIST: "BADGE_LIST",
  BADGE_SINGLE: "BADGE_SINGLE",
  BODY_GROUP_FORM_MODEL: "BODY_GROUP_FORM_MODEL",
  BODY_GROUP_LIST: "BODY_GROUP_LIST",
  BODY_GROUP_SINGLE: "BODY_GROUP_SINGLE",
  CHALLENGE_FORM_MODEL: "CHALLENGE_FORM_MODEL",
  CHALLENGE_LIST: "CHALLENGE_LIST",
  CHALLENGE_SINGLE: "CHALLENGE_SINGLE",
  CHALLENGE_USER_LIST: "CHALLENGE_USER_LIST",
  DAY_WORKOUT_FORM_MODEL: "DAY_WORKOUT_FORM_MODEL",
  DAY_WORKOUT_LIST: "DAY_WORKOUT_LIST",
  DAY_WORKOUT_SINGLE: "DAY_WORKOUT_SINGLE",
  DELETE_ALERT_VISIBLE: "DELETE_ALERT_VISIBLE",
  ERROR_ALERT_VISIBLE: "ERROR_ALERT_VISIBLE",
  EQUIPMENT_FORM_MODEL: "EQUIPMENT_FORM_MODEL",
  EQUIPMENT_LIST: "EQUIPMENT_LIST",
  EQUIPMENT_SINGLE: "EQUIPMENT_SINGLE",
  EXERCISE_FORM_MODEL: "EXERCISE_FORM_MODEL",
  EXERCISE_LIST: "EXERCISE_LIST",
  EXERCISE_SINGLE: "EXERCISE_SINGLE",
  EXERCISE_CATEGORY_FORM_MODEL: "EXERCISE_CATEGORY_FORM_MODEL",
  EXERCISE_CATEGORY_LIST: "EXERCISE_CATEGORY_LIST",
  EXERCISE_CATEGORY_SINGLE: "EXERCISE_CATEGORY_SINGLE",
  EXERCISE_GOAL_FORM_MODEL: "EXERCISE_GOAL_FORM_MODEL",
  EXERCISE_GOAL_LIST: "EXERCISE_GOAL_LIST",
  EXERCISE_GOAL_SINGLE: "EXERCISE_GOAL_SINGLE",
  EXERCISE_POSITION_FORM_MODEL: "EXERCISE_POSITION_FORM_MODEL",
  EXERCISE_POSITION_LIST: "EXERCISE_POSITION_LIST",
  EXERCISE_POSITION_SINGLE: "EXERCISE_POSITION_SINGLE",
  FAQ_FORM_MODEL: "FAQ_FORM_MODEL",
  FAQ_LIST: "FAQ_LIST",
  FAQ_SINGLE: "FAQ_SINGLE",
  FORCE_FORM_MODEL: "FORCE_FORM_MODEL",
  FORCE_LIST: "FORCE_LIST",
  FORCE_SINGLE: "FORCE_SINGLE",
  GOALS_FORM_MODEL: "GOALS_FORM_MODEL",
  GOALS_LIST: "GOALS_LIST",
  GOALS_SINGLE: "GOALS_SINGLE",
  JOINT_FORM_MODEL: "JOINT_FORM_MODEL",
  JOINT_LIST: "JOINT_LIST",
  JOINT_SINGLE: "JOINT_SINGLE",
  LOADER_HIDE: "LOADER_HIDE",
  LOADER_VISIBLE: "LOADER_VISIBLE",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  MECHANICS_JOINT_FORM_MODEL: "MECHANICS_JOINT_FORM_MODEL",
  MECHANICS_JOINT_LIST: "MECHANICS_JOINT_LIST",
  MECHANICS_JOINT_SINGLE: "MECHANICS_JOINT_SINGLE",
  MOVEMENT_PATTERN_FORM_MODEL: "MOVEMENT_PATTERN_FORM_MODEL",
  MOVEMENT_PATTERN_LIST: "MOVEMENT_PATTERN_LIST",
  MOVEMENT_PATTERN_SINGLE: "MOVEMENT_PATTERN_SINGLE",
  MUSCLE_FORM_MODEL: "MUSCLE_FORM_MODEL",
  MUSCLE_LIST: "MUSCLE_LIST",
  MUSCLE_SINGLE: "MUSCLE_SINGLE",
  PRIMARY_JOINT_FORM_MODEL: "PRIMARY_JOINT_FORM_MODEL",
  PRIMARY_JOINT_LIST: "PRIMARY_JOINT_LIST",
  PRIMARY_JOINT_SINGLE: "PRIMARY_JOINT_SINGLE",
  PRIMARY_MUSCLE_FORM_MODEL: "PRIMARY_MUSCLE_FORM_MODEL",
  PRIMARY_MUSCLE_LIST: "PRIMARY_MUSCLE_LIST",
  PRIMARY_MUSCLE_SINGLE: "PRIMARY_MUSCLE_SINGLE",
  PROGRAMME_FORM_MODEL: "PROGRAMME_FORM_MODEL",
  PROGRAMME_LIST: "PROGRAMME_LIST",
  PROGRAMME_SINGLE: "PROGRAMME_SINGLE",
  PROGRAMME_USER_LIST: "PROGRAMME_USER_LIST",
  SUCCESS_ALERT_VISIBLE: "SUCCESS_ALERT_VISIBLE",
  SYNERGIST_MUSCLE_FORM_MODEL: "SYNERGIST_MUSCLE_FORM_MODEL",
  SYNERGIST_MUSCLE_LIST: "SYNERGIST_MUSCLE_LIST",
  SYNERGIST_MUSCLE_SINGLE: "SYNERGIST_MUSCLE_SINGLE",
  TUTORIAL_FORM_MODEL: "TUTORIAL_FORM_MODEL",
  TUTORIAL_LIST: "TUTORIAL_LIST",
  TUTORIAL_SINGLE: "TUTORIAL_SINGLE",
  USERS_LIST: "USERS_LIST",
  USERS_SINGLE: "USERS_SINGLE",
  USER_COMMENTS_FORM_MODEL: "USER_COMMENTS_FORM_MODEL",
  USER_COMMENTS_LIST: "USER_COMMENTS_LIST",
  USER_COMMENTS_SINGLE: "USER_COMMENTS_SINGLE",
  USER_DETAIL: "USER_DETAIL",
  WORKOUT_ADD_EXER_FORM_MODEL: "WORKOUT_ADD_EXER_FORM_MODEL",
  WORKOUT_ADD_EXER_SINGLE: "WORKOUT_ADD_EXER_SINGLE",
  WORKOUT_DETAIL: "WORKOUT_DETAIL",
  WORKOUT_FORM_MODEL: "WORKOUT_FORM_MODEL",
  WORKOUT_LIST: "WORKOUT_LIST",
  WORKOUT_SINGLE: "WORKOUT_SINGLE",
};
