import { types } from "../../Actions/types";

const initialState = {
  bodygrouplist: [],
  error: null,
  bodyGroupSingle: "",
  popupmodel: "",
};

const BodyGroup = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.BODY_GROUP_LIST:
      return {
        ...state,
        bodygrouplist: payload,
        error,
      };
    case types.BODY_GROUP_SINGLE:
      return {
        ...state,
        bodyGroupSingle: payload,
        error,
      };
    case types.BODY_GROUP_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default BodyGroup;
