import { notification, notificationAdd } from "../../services/notifi.service";
import { types } from "./types";

export const sendNotification = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await notificationAdd(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const fetchNotifications = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await notification(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
