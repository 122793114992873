import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const goalsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.GOAL, data);
  return response;
};

export const goalsEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.GOAL, data);
  return response;
};

export const goalsRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.GOAL + `/${id}`);
  return response;
};

export const goals = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.GOAL}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};
