import React from "react";
import { useDrag } from "react-dnd";

const DraggableItem = ({ item }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "ITEM",
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      className="exer-view"
      ref={drag}
      style={{
        padding: "8px",
        margin: "4px",
        backgroundColor: "lightblue",
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <div className="exer-img">
        <img src={item?.oneThumb} alt={item?.exerciseName} />
      </div>
      <p className="exer-name">{item.exerciseName}</p>
    </div>
  );
};

export default DraggableItem;
