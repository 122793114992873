import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faqDelete, faqList } from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import { Constants } from "../../Common/Constants";
import EditListButton from "../../Common/Fields/EditListButton";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import PageHeader from "../../Common/PageHeader";
import TableList from "../../Common/UiModel/TableList";
import ReactPagination from "../../Common/Fields/ReactPagination";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import FaqForm from "./FaqForm";

const FaqList = () => {
  const dispatch = useDispatch();
  const { faqlist, popupmodel } = useSelector((state) => state.Cms);
  const [faqData, setFaqData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    // FAQ list api
    dispatch(faqList());
    dispatch({
      type: types.FAQ_SINGLE,
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Set data for FAQ from store
    setFaqData(faqlist?.items);
  }, [faqlist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Function to hit FAQ list
  const getData = (searchKey) => {
    const data = { search: searchKey || "" };
    dispatch(faqList(data));
  };

  const onDelete = (delete_id) => {
    // Delete FAQ
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(faqDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(faqList());
            setPageIndex(1);
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Function to handle edit functionality for FAQ
  const handleEdit = (row) => {
    dispatch({
      type: types.FAQ_FORM_MODEL,
      payload: {
        msg: Constants.editFaq,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.FAQ_SINGLE,
      payload: row,
    });
  };

  // Columns to FAQ Table List
  const columns = [
    {
      name: Constants.id,
      // width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.ques,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          {row?.ques}
        </Link>
      ),
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdown to edit, delete FAQ
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <EditListButton
                      onClick={() => {
                        handleEdit(row);
                      }}
                    />
                  </div>
                  <div className="list-group">
                    <DeleteListButton
                      onClick={() => {
                        onDelete(row._id);
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(faqList(data));
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.faqList}
          buttonLink={true}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.FAQ_FORM_MODEL,
              payload: { msg: Constants.addFaq, show: true },
            });
          }}
        />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        <div className="col-md-4">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByQues}
            onChange={(event) => {
              setSearch(event.target.value);
              setPageIndex(1);
              if (event.target.value === "") {
                setSearch("");
                getData("");
              }
            }}
          ></input>
        </div>
      </div>
      <TableList
        columns={columns}
        data={faqData}
        FaqdefaultSortFieldId
        highlightOnHover
      />
      {faqlist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={faqlist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
          pageIndex={pageIndex - 1}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.FAQ_SINGLE, payload: "" });
          dispatch({ type: types.FAQ_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<FaqForm />}
      />
    </div>
  );
};

export default FaqList;
