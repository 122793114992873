import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { fetchNotifications } from "../../Redux/Actions";
import { Constants } from "../Common/Constants";
import { Dropdown } from "react-bootstrap";
import ViewListButton from "../Common/Fields/ViewListButton";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";
import ModalPopup from "../Common/UiModel/ModalPopup";
import NotificationForm from "./NotificationForm";

const NotificationList = () => {
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [showNotificanDetail, setShowNotificanDetail] = useState("");

  useEffect(() => {
    // Notifications list api
    notificationList();
  }, []);

  // Fucntion to hit notifi API
  const notificationList = async () => {
    const response = await dispatch(fetchNotifications());
    if (response?.data) setNotificationData(response?.data?.data);
  };

  // Columns to Notification Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.title,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.subject}
          onClick={() => navigator.clipboard.writeText(row?.subject)}
        >
          {row?.subject?.slice(0, 15) + "..." || Constants.na}
        </span>
      ),
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.notification_date).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.notification_date).format(Constants.timeFormat),
    },
    {
      name: Constants.setRule,
      cell: (row) =>
        Constants.setRuleOptions?.find((item) => item.value === row?.set_rule)
          ?.label || Constants.na,
    },
    // Dropdown to view notification
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        setShowNotificanDetail(row);
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(fetchNotifications(data));
  };

  return (
    <>
      <div className="mt-3">
        <PageHeader
          pagetitle={`${Constants.notification} ${Constants.history}`}
        />
      </div>
      <TableList
        columns={columns}
        data={notificationData?.items}
        defaultSortFieldId
        highlightOnHover
      />
      {notificationData?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={notificationData?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      {showNotificanDetail && (
        <ModalPopup
          show={showNotificanDetail}
          onHide={() => {
            setShowNotificanDetail("");
          }}
          dialogClassName={"modal-lg"}
          title={Constants.detail}
          component={<NotificationForm data={showNotificanDetail} />}
        />
      )}
    </>
  );
};
export default NotificationList;
