import React, { useEffect } from "react";
import Profile from "../../assets/images/profile_av.svg";
import { connect } from "react-redux";
import { Onopenmodalsetting } from "../../Redux/Actions/Action";
import { useNavigate } from "react-router-dom";
import { Constants } from "./Constants";
import { useSelector } from "react-redux";
import { RoutesUrl } from "./RoutesUrl";

const Header = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.Auth);

  // check if user is logged in or not
  useEffect(() => {
    if (isLoggedIn === false) {
      navigate(RoutesUrl.signIn);
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="header">
      <nav className="navbar py-4">
        <div className="container-xxl">
          <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
            <p className="welcome-msg">{`${Constants.welcome} ${Constants.admin}`}</p>
            <div className="d-flex mx-2 mt-1"></div>
            <img
              className="avatar lg rounded-circle img-thumbnail"
              src={Profile}
              alt="profile"
            />
          </div>
          <button
            className="navbar-toggler p-0 border-0 menu-toggle order-3"
            type="button"
            onClick={() => {
              const sidebar = document.getElementById("mainsidemenu");
              if (sidebar) {
                if (sidebar.classList.contains("open")) {
                  sidebar.classList.remove("open");
                } else {
                  sidebar.classList.add("open");
                }
              }
            }}
          >
            <span className="fa fa-bars"></span>
          </button>
          <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-4  "></div>
        </div>
      </nav>
    </div>
  );
};

const mapSatetToProps = ({ Mainreducer }) => ({
  Mainreducer,
});

export default connect(mapSatetToProps, {
  Onopenmodalsetting,
})(Header);
