import { types } from "../../Actions/types";

const initialState = {
  movementpatternlist: [],
  error: null,
  movementPatternSingle: "",
  popupmodel: "",
};

const MovementPattern = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.MOVEMENT_PATTERN_LIST:
      return {
        ...state,
        movementpatternlist: payload,
        error,
      };
    case types.MOVEMENT_PATTERN_SINGLE:
      return {
        ...state,
        movementPatternSingle: payload,
        error,
      };
    case types.MOVEMENT_PATTERN_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default MovementPattern;
