import { types } from "../../Actions/types";

const initialState = {
  primaryjointlist: [],
  error: null,
  primaryJointSingle: "",
  popupmodel: "",
};

const PrimaryJoint = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.PRIMARY_JOINT_LIST:
      return {
        ...state,
        primaryjointlist: payload,
        error,
      };
    case types.PRIMARY_JOINT_SINGLE:
      return {
        ...state,
        primaryJointSingle: payload,
        error,
      };
    case types.PRIMARY_JOINT_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default PrimaryJoint;
