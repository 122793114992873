import React from "react";
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import ChallengeForm from "../ChallengeForm";

const ChallengeDetailPage = () => {
  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.detail} />
      <ChallengeForm />
    </div>
  );
};

export default ChallengeDetailPage;
