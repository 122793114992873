import { types } from "../Actions/types";

const initialState = {
  programmelist: [],
  dayworkoutlist: [],
  programmeuserlist: [],
  error: null,
  programmeSingle: "",
  dayWorkoutSingle: "",
  popupmodel: "",
  dayWorkoutPopupmodel: "",
};

const Programme = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.PROGRAMME_LIST:
      return {
        ...state,
        programmelist: payload,
        error,
      };
    case types.PROGRAMME_SINGLE:
      return {
        ...state,
        programmeSingle: payload,
        error,
      };
    case types.PROGRAMME_USER_LIST:
      return {
        ...state,
        programmeuserlist: payload,
        error,
      };
    case types.PROGRAMME_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };
    case types.DAY_WORKOUT_SINGLE:
      return {
        ...state,
        dayWorkoutSingle: payload,
        error,
      };
    case types.DAY_WORKOUT_LIST:
      return {
        ...state,
        dayworkoutlist: payload,
        error,
      };
    case types.DAY_WORKOUT_FORM_MODEL:
      return {
        ...state,
        dayWorkoutPopupmodel: payload,
      };

    default:
      return state;
  }
};

export default Programme;
