import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const workoutAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.WORKOUT, data);
  return response;
};

export const workoutEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.WORKOUT, data);
  return response;
};

export const workoutRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.WORKOUT + `/${id}`);
  return response;
};

export const workout = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.WORKOUT}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};

export const fetchWorkoutDetail = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.WORKOUT +
      `/${data?.id}${`?search=${data?.search || ""}&equipment=${
        data?.equipment || ""
      }&primaryMuscles=${data?.primaryMuscles || ""}`}&force=${
        data?.force || ""
      }`
  );
  return response;
};

export const workoutExerciseAdd = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.WORKOUT_EXERCISE,
    data
  );
  return response;
};

export const workoutExerciseSupersetAdd = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.WORKOUT_SUPERSET_EXERCISE,
    data
  );
  return response;
};

export const workoutExerciseEdit = async (data) => {
  const response = await httpServiceInstance.patch(
    ApiUrl.WORKOUT_EXERCISE,
    data
  );
  return response;
};

export const workoutExerciseRemove = async (data) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.WORKOUT_EXERCISE,
    data
  );
  return response;
};
