/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersList } from "../../Redux/Actions/Users";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";
import dayjs from "dayjs";
import titleCase from "../Common/Functions/titleCase";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";

const UsersList = () => {
  const dispatch = useDispatch();
  const { userslist } = useSelector((state) => state.Users);
  const [usersData, setUsersData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    // Users list API
    getUsersData();
  }, []);

  useEffect(() => {
    // Set user list data
    setUsersData(userslist.user);
  }, [userslist]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // De-bounce function for search with delay of 500 seconds
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        getUsersData(search);
      }, 500);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [search]);

  // Columns for User list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.email,
      selector: (row) => (
        <Link to={`${RoutesUrl.users}/${row?._id}`}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.email}
          >
            {row?.email}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.name,
      selector: (row) => row?.name || Constants.na,
    },
    {
      name: Constants.gender,
      selector: (row) => titleCase(row?.gender),
    },
    {
      name: Constants.dob,
      selector: (row) =>
        row?.dob ? dayjs(row?.dob).format(Constants.dateFormat) : Constants.na,
    },
    {
      name: Constants.height,
      selector: (row) =>
        row?.heightType === Constants.heightTypeFt
          ? `${row.feet} ft ${row.inchs} in`
          : `${row.centimeters} cms`,
    },
    {
      name: Constants.weight,
      selector: (row) =>
        `${row.weight} ${
          row.weightType === Constants.weightTypeLbs ? "lbs" : "kgs"
        }`,
    },
  ];

  const getUsersData = (searchKey) => {
    // User list API based on all filters
    const data = {
      search: searchKey,
    };
    dispatch(usersList(data));
  };

  // Pagination fuction
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(usersList(data));
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader pagetitle={Constants.customerList} />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        {/* Search filter */}
        <div className="col-md-4">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByEmail}
            onChange={(event) => {
              setSearch(event.target.value);
              setPageIndex(1);
              if (event.target.value === "") {
                setSearch("");
                getUsersData("");
              }
            }}
          ></input>
        </div>
      </div>
      <TableList
        columns={columns}
        data={usersData}
        defaultSortFieldId
        highlightOnHover
      />
      {userslist?.totalCount > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={userslist?.totalCount}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
          pageIndex={pageIndex - 1}
        />
      )}
    </div>
  );
};

export default UsersList;
