import { Constants } from "../../components/Common/Constants";
import { types } from "../Actions/types";

const user = localStorage.getItem(Constants.accessToken);

const initialState = user
  ? { isLoggedIn: true, user, error: null, permissionsArr: [] }
  : { isLoggedIn: false, user: null, error: null };

const Auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        error: null,
      };

    case types.LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: payload.error,
      };

    case types.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: null,
      };

    default:
      return state;
  }
};

export default Auth;
