import {
  exercisecategory,
  exercisecategoryAdd,
  exercisecategoryEdit,
  exercisecategoryRemove,
} from "../../services/exerciseCategory.service";
import { types } from "./types";

export const exerciseCategoryInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exercisecategoryAdd(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseCategoryList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exercisecategory(data);
  if (response.status === true) {
    const result = response?.data?.exerciseCategories;
    dispatch({ type: types.EXERCISE_CATEGORY_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.EXERCISE_CATEGORY_LIST,
      payload: [],
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseCategoryUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exercisecategoryEdit(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseCategoryDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exercisecategoryRemove(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
