import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const exercisePositionAdd = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.EXERCISE_POSITION,
    data
  );
  return response;
};

export const exercisePositionEdit = async (data) => {
  const response = await httpServiceInstance.patch(
    ApiUrl.EXERCISE_POSITION,
    data
  );
  return response;
};

export const exercisePositionRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.EXERCISE_POSITION + `/${id}`
  );
  return response;
};

export const exercisePosition = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.EXERCISE_POSITION}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}`
  );
  return response;
};
