import React, { Fragment, useState } from "react";
import { useDrop } from "react-dnd";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import { useDispatch } from "react-redux";
import {
  getWorkoutDetail,
  workoutExerciseDelete,
  workoutExerciseInsert,
} from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import { Constants } from "../../Common/Constants";
import { Dropdown } from "react-bootstrap";
import EditListButton from "../../Common/Fields/EditListButton";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import TableList from "../../Common/UiModel/TableList";
import { useParams } from "react-router-dom";
import PageHeader from "../../Common/PageHeader";

const setTypes = [
  { label: "Super Set", type: Constants.superset },
  { label: "Compound Set", type: Constants.compoundSet },
  { label: "Tri Set", type: Constants.triSet },
  { label: "Pyramid Set", type: Constants.pyramidSet },
];

const DroppingArea = ({ droppedItems, onDrop }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [setItems, setSetItems] = useState([]);
  const [type, setType] = useState();

  const [{ isOver: isOverSet }, dropSet] = useDrop(() => ({
    accept: "ITEM",
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ isOver: isOverSuperSet }, dropSuperSet] = useDrop(() => ({
    accept: "ITEM",
    drop: (item) => handleSuperSetDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const onDelete = (row) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(
          workoutExerciseDelete({
            id,
            setId:
              row?.type === Constants.exerciseKey
                ? row?.exerciseDetails?.[0]?.set?._id
                : row?.exerciseDetails?.map((set) => set?.superset),
          })
        ).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(getWorkoutDetail({ id }));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  const handleEdit = (row) => {
    dispatch({
      type: types.WORKOUT_ADD_EXER_FORM_MODEL,
      payload: { msg: Constants.editExercise, show: true },
    });
    dispatch({
      type: types.WORKOUT_ADD_EXER_SINGLE,
      payload:
        row?.type === Constants.exerciseKey
          ? row?.exerciseDetails?.[0]?.workoutProgramSetsDetails
          : row?.exerciseDetails?.[0]?.workoutProgramSuperSetsDetails,
    });
  };

  const handleSuperSetDrop = (item) => {
    setSetItems((prevItems) => {
      const isItemExists = prevItems.some(
        (existingItem) => existingItem._id === item._id
      );
      // If the item exists, return the previous items without modification
      if (isItemExists) return prevItems;
      return [...prevItems, item];
    });
  };

  // Columns to Workout Table List
  const superSetColumns = [
    {
      name: Constants.name,
      cell: (row) => <div>{row?.exerciseName}</div>,
    },
    // Dropdown to edit, delete Workout
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow btn-lists dropdown-animation dropdown-menu-end mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <DeleteListButton
                      onClick={() => {
                        const items = [...setItems];
                        const idx = items.find((elt) => elt?._id === row?._id);
                        items.splice(idx, 1);
                        setSetItems(items);
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  const handleAdd = async () => {
    const ids = setItems?.map((elt) => elt._id);
    const resp = await dispatch(
      workoutExerciseInsert({
        exerciseId: ids,
        id,
        type,
      })
    );
    if (resp?.data?.setDetail?._id) {
      // Add the item to the dropped items list
      dispatch(getWorkoutDetail({ id }));
      dispatch({
        type: types.WORKOUT_ADD_EXER_FORM_MODEL,
        payload: {
          msg: Constants.addExercise,
          show: true,
          setId: resp?.data?.setDetail?._id,
          type,
        },
      });
      setType("");
      setSetItems([]);
    }
  };

  const DropdownComp = ({ set }) => (
    <div className="switch-tn2 position-relative btn-h">
      <div className="remove-drop-ic">
        <Dropdown className="dropdown user-profilem">
          <Dropdown.Toggle
            as="a"
            className="nav-link dropdown-toggle pulse p-0 margin-b"
            href="#!"
            role="button"
          >
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu rounded-lg shadow btn-lists dropdown-animation dropdown-menu-end mt-1 ">
            <div className="outer-dot2 btn-list2">
              <div className="list-group">
                <EditListButton
                  onClick={() => {
                    handleEdit(set);
                  }}
                />
              </div>
              <div className="list-group">
                <DeleteListButton
                  onClick={() => {
                    onDelete(set);
                  }}
                />
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );

  return (
    <>
      <div className="mt-3">
        <PageHeader pagetitle={Constants.exerciseList} />
      </div>
      <div className="btn_sec">
        <ul className="nav btn_nav">
          {setTypes.map((setTypeItem) => (
            <li key={setTypeItem.type}>
              <button
                type="button"
                className="set_button"
                onClick={() => {
                  setType(setTypeItem.type);
                  setSetItems([]);
                }}
              >
                {setTypeItem.label}
              </button>
            </li>
          ))}
        </ul>
        {type && (
          <>
            <button
              type="button"
              className="set_button btn btn-primary btn_adds"
              onClick={handleAdd}
              disabled={setItems?.length === 0}
            >
              {Constants.add}
            </button>
            <button
              type="button"
              className="set_button btn btn-primary btn_adds ms-1"
              onClick={() => {
                setType("");
                setSetItems([]);
              }}
            >
              {Constants.delete}
            </button>
          </>
        )}
      </div>
      {type && (
        <div
          ref={dropSuperSet}
          className="set-type"
          style={{
            marginTop: "20px",
          }}
        >
          <div className="head_exercise">
            <h3
              className=""
              style={{
                backgroundColor: isOverSuperSet ? "#add8e6" : "#e4f5f3",
              }}
            >
              Drop Area
            </h3>
          </div>
          <TableList
            columns={superSetColumns}
            data={setItems}
            defaultSortFieldId
            highlightOnHover
          />
        </div>
      )}
      <div
        ref={dropSet}
        style={{ backgroundColor: isOverSet ? "#add8e6" : "lightgrey" }}
      >
        <div className="exercise_table taable_307">
          <table className="table ">
            <thead>
              <tr>
                <th>Exercise</th>
                <th>Reps</th>
                <th>Weight</th>
                <th>Rest</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {droppedItems?.map((set) => (
                <Fragment key={set?.order}>
                  {set?.type === Constants.exerciseKey ? (
                    <>
                      <tr className="exercise_bg">
                        <td>{set?.exerciseDetails?.[0]?.set?.exerciseName} </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="center_text">
                          <DropdownComp set={set} />
                        </td>
                      </tr>
                      {set?.exerciseDetails?.[0]?.workoutProgramSetsDetails?.setDetails?.map(
                        (setDetail) => (
                          <tr key={setDetail?.name}>
                            <td>{setDetail?.name}</td>
                            <td>{setDetail?.reps}</td>
                            <td>{setDetail?.recommendedWeight}</td>
                            <td>{setDetail?.rest}</td>
                            <td></td>
                          </tr>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <tr className="exercise_bg super_set">
                        <td>{`${set?.type.toUpperCase()} of ${
                          set?.exerciseDetails?.length
                        } exercises`}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="center_text">
                          <DropdownComp set={set} />
                        </td>
                      </tr>
                      {set?.exerciseDetails?.map((subSet) => (
                        <tr
                          className="exercise_bg"
                          key={subSet?.supersetExerciseDetail?.exerciseName}
                        >
                          <td>
                            {subSet?.supersetExerciseDetail?.exerciseName}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      ))}
                      {set?.exerciseDetails?.[0]?.workoutProgramSuperSetsDetails?.setDetails?.map(
                        (setDetail) => (
                          <tr key={setDetail?._id}>
                            <td>{setDetail?.name}</td>
                            <td>{setDetail?.reps}</td>
                            <td>{setDetail?.recommendedWeight}</td>
                            <td>{setDetail?.rest}</td>
                            <td></td>
                          </tr>
                        )
                      )}
                    </>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DroppingArea;
