import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import {
  goalTimingInsert,
  goalTimingList,
  goalTimingUpdate,
} from "../../Redux/Actions";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import PageHeader from "../Common/PageHeader";
import { types } from "../../Redux/Actions/types";
import ReactSelect from "../Common/Fields/ReactSelect";

const GoalTimingForm = () => {
  const dispatch = useDispatch();
  const [goalTimingData, setGoalTimingData] = useState();
  const [type, setType] = useState(Constants.goalTimingType?.[0]);
  // Initial values to goal TIming form
  const [initialValues, setInitialValues] = useState({
    unit: null,
    type: Constants.goalTimingType?.[0]?.value,
  });

  // Validations for exercise position form
  const validationSchema = Yup.object().shape({
    unit: Yup.number().required(Constants.required),
    type: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    fetchGoalTimingData();
  }, []);

  const fetchGoalTimingData = async () => {
    const goalTImingData = await dispatch(goalTimingList());
    setGoalTimingData(goalTImingData?.data?.items?.[0] || null);
  };

  useEffect(() => {
    // Set initial values to pre fill exercise position form
    if (goalTimingData) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (goalTimingData[key]) {
          editValue[key] = goalTimingData[key];
          if (key === "type") {
            setType(
              Constants.goalTimingType.find(
                (elt) => elt.value === editValue[key]
              )
            );
          }
        }
        setInitialValues(editValue);
      }
    }
  }, [goalTimingData]);

  return (
    <div className="container-xxl">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          values.type = type?.value;
          // Add or update exercise position API
          let submitFunc = goalTimingInsert(values);
          if (goalTimingData?._id) {
            values.id = goalTimingData?._id;
            submitFunc = goalTimingUpdate(values);
          }
          dispatch(submitFunc).then((response) => {
            if (response.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response.error,
              });
            } else {
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response.message,
              });
            }
            dispatch(goalTimingList());
          });
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-control  mt-3 list-view card">
            <PageHeader pagetitle={Constants.goalTiming} />
            <div className="row">
              <div className="col-md-6 mb-2">
                <TextField
                  type="number"
                  name="unit"
                  className="form-control"
                  errors={touched?.unit ? errors?.unit : undefined}
                  touched={touched?.unit}
                  label={Constants.unit}
                />
              </div>
              <div className="col-md-6 mb-2">
                <ReactSelect
                  label={`${Constants.type}:`}
                  required={false}
                  options={Constants.goalTimingType}
                  onChange={(e) => setType(e)}
                  value={type}
                />
              </div>
            </div>
            <ButtonCustom label={Constants.submit} type="submit" />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GoalTimingForm;
