import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { badgeInsert, badgeList, badgeUpdate } from "../../Redux/Actions";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import SingleSelect from "../Exercise/SingleSelect";
import ReactSelect from "../Common/Fields/ReactSelect";
import VideoTusUpload from "../Common/Fields/VideoTusUpload";
import ViewVideoFile from "../Common/Fields/ViewVideoFile";

const BadgeForm = () => {
  const dispatch = useDispatch();
  const { badgeSingle } = useSelector((state) => state.Badge);
  const { exerciseCategorylist } = useSelector(
    (state) => state.ExerciseCategory
  );
  const [selectedValues, setSelectedValues] = useState({
    type: "",
    subType: "",
    exerciseCategoryId: "",
  });
  // Initial values to challenge
  const [initialValues, setInitialValues] = useState({
    name: "",
    type: "",
    subType: "",
    exerciseCategoryId: "",
    value: "",
    unit: "",
    image: "",
    video: "",
  });

  // Validations for challenge
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    type: Yup.string().required(Constants.required),
    subType: Yup.string().test(
      "is-required",
      Constants.required,
      function (value) {
        const { type } = this.parent;
        if (["weekly", "monthly", "lifetime"].includes(type)) {
          return !!value;
        }
        return true;
      }
    ),
    value: Yup.string().required(Constants.required),
    image: Yup.string().required(Constants.required),
    exerciseCategoryId: Yup.string().test(
      "is-required",
      Constants.required,
      function (value) {
        const { type } = this.parent;
        if (["muscleGroup"].includes(type)) {
          return !!value;
        }
        return true;
      }
    ),
    video: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill challenge
    if (badgeSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (badgeSingle[key]) {
          editValue[key] = badgeSingle[key];
          if (key === "type") {
            setSelectedValues((prev) => ({
              ...prev,
              type: Constants.badgeTypeOptions?.find(
                (elt) => elt.value === badgeSingle[key]
              ),
            }));
          }
          if (key === "subType") {
            setSelectedValues((prev) => ({
              ...prev,
              subType: Constants.badgeSubTypeOptions?.find(
                (elt) => elt.value === badgeSingle[key]
              ),
            }));
          }
          if (key === "exerciseCategoryId") {
            editValue[key] = badgeSingle[key]?._id;
            setSelectedValues((prev) => ({
              ...prev,
              exerciseCategoryId: {
                label: badgeSingle[key]?.title,
                value: badgeSingle[key]?._id,
              },
            }));
          }
        }
        setInitialValues(editValue);
      }
    }
  }, [badgeSingle, exerciseCategorylist]);

  const handleFileChange = (file, setFieldValue, field) => {
    // Add  image for challenge
    setFieldValue(field, file?.[0]);
  };

  const onSingleSelectChange = (e, key, setKey) => {
    setSelectedValues((prev) => ({ ...prev, [key]: e }));
    setKey(key, e.value);
  };

  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update challenge API
        if (
          values?.type === "muscleGroup" ||
          values?.subType === "weightLifted"
        )
          values.unit = "kgs";
        else if (values?.subType === "timeUnderTension") {
          values.unit = values?.type === "lifetime" ? "hour" : "seconds";
        }
        let submitFunc = badgeInsert(values);
        if (badgeSingle._id) {
          values.id = badgeSingle._id;
          submitFunc = badgeUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.BADGE_SINGLE, payload: "" });
            dispatch({ type: types.BADGE_FORM_MODEL, payload: "" });
          }
          dispatch(badgeList());
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-4 mb-2">
            <ReactSelect
              label={`${Constants.type}`}
              required={true}
              options={Constants.badgeTypeOptions}
              errors={touched?.type ? errors?.type : undefined}
              touched={touched?.type}
              onChange={(e) => onSingleSelectChange(e, "type", setFieldValue)}
              value={selectedValues.type}
            />
          </div>
          {["weekly", "monthly", "lifetime"].includes(values.type) && (
            <div className="col-md-4 mb-2">
              <ReactSelect
                label={`${Constants.subType}`}
                required={true}
                options={Constants.badgeSubTypeOptions}
                errors={touched?.subType ? errors?.subType : undefined}
                touched={touched?.subType}
                onChange={(e) =>
                  onSingleSelectChange(e, "subType", setFieldValue)
                }
                value={selectedValues.subType}
              />
            </div>
          )}
          {values.type === "muscleGroup" && (
            <div className="col-md-4 mb-2">
              <SingleSelect
                label={`${Constants.muscleGroup}`}
                required={true}
                options={exerciseCategorylist?.exerciseCategory || []}
                onChange={(e) =>
                  onSingleSelectChange(e, "exerciseCategoryId", setFieldValue)
                }
                value={selectedValues.exerciseCategoryId}
                errors={
                  touched?.exerciseCategoryId
                    ? errors?.exerciseCategoryId
                    : undefined
                }
                touched={touched?.exerciseCategoryId}
              />
            </div>
          )}
          <div className="col-md-4 mb-2">
            <TextField
              type="number"
              name="value"
              className="form-control"
              errors={touched?.value ? errors?.value : undefined}
              touched={touched?.value}
              label={`${Constants.value}${
                values?.type === "muscleGroup" ||
                values?.subType === "weightLifted"
                  ? "(kgs)"
                  : values?.subType === "timeUnderTension"
                  ? values?.type === "lifetime"
                    ? "(hour)"
                    : "(seconds)"
                  : ""
              }`}
            />
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">{`${Constants.image}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.image && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="381"
                    height="426"
                    errors={touched?.image ? errors?.image : undefined}
                    touched={touched?.image}
                    label={`${Constants.upload} ${Constants.image}`}
                    handleFile={(files) =>
                      handleFileChange(files, setFieldValue, "image")
                    }
                    maxFiles={1}
                  />
                )}
              </div>
              <div className="after-up">
                {values && values?.image && (
                  <ViewImageFile
                    file={values?.image}
                    onClick={() => setFieldValue("image", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">{`${Constants.video}`}</label>
            <RequiredIcon />
            <div>
              {values && !values?.video && (
                <VideoTusUpload
                  errors={touched?.video ? errors?.video : undefined}
                  touched={touched?.video}
                  label={`${Constants.upload} ${Constants.video}`}
                  handleFile={(video) =>
                    handleVideoChange(video, setFieldValue, "video")
                  }
                />
              )}
              {values && values?.video && (
                <ViewVideoFile
                  file={values?.video}
                  onClick={() => {
                    setFieldValue("video", "");
                  }}
                />
              )}
            </div>
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default BadgeForm;
