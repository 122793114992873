import React from "react";
import SignIn from "../../assets/images/SignIn.png";

const AuthLeft = () => {
  // static image on signup page
  return (
    <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h10022">
      <div style={{ maxWidth: "50rem" }}>
        <div>
          <img src={SignIn} alt="login-img" className="signin-img" />
        </div>
      </div>
    </div>
  );
};
export default AuthLeft;
