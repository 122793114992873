import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import ReactSelect from "../Common/Fields/ReactSelect";
import SingleSelect from "./SingleSelect";
import MultipleSelect from "./MultipleSelect";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import VideoTusUpload from "../Common/Fields/VideoTusUpload";
import ViewVideoFile from "../Common/Fields/ViewVideoFile";
import {
  initValues,
  selectedInitValues,
  validationSchema,
} from "./Validations";
import {
  exerciseInsert,
  exerciseList,
  exerciseUpdate,
} from "../../Redux/Actions";
import { preFilledValues } from "./preFilledValues";

const ExerciseForm = () => {
  const dispatch = useDispatch();
  const { exerciseSingle } = useSelector((state) => state.Exercise);
  const { exerciseCategorylist } = useSelector(
    (state) => state.ExerciseCategory
  );
  const { exercisepositionlist } = useSelector(
    (state) => state.ExercisePosition
  );
  const { mechanicsjointlist } = useSelector((state) => state.MechanicsJoint);
  const { exercisegoallist } = useSelector((state) => state.ExerciseGoal);
  const { movementpatternlist } = useSelector((state) => state.MovementPattern);
  const { forcelist } = useSelector((state) => state.Force);
  const { jointlist } = useSelector((state) => state.Joint);
  const { musclelist } = useSelector((state) => state.Muscle);
  const { equipmentlist } = useSelector((state) => state.Equipment);
  const { bodygrouplist } = useSelector((state) => state.BodyGroup);
  const { primaryjointlist } = useSelector((state) => state.PrimaryJoint);
  const { primarymusclelist } = useSelector((state) => state.PrimaryMuscle);
  const { synergistmusclelist } = useSelector((state) => state.SynergistMuscle);
  const [selectedValues, setSelectedValues] = useState(selectedInitValues);
  const [initialValues, setInitialValues] = useState(initValues);

  useEffect(() => {
    // Set initial values to pre fill Exercise form
    if (exerciseSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (exerciseSingle[key]) {
          editValue[key] = exerciseSingle[key];
          const selectedVal = preFilledValues(exerciseSingle);
          setSelectedValues(selectedVal);
        }
        setInitialValues(editValue);
      }
    }
  }, [exerciseSingle]);

  const onSingleSelectChange = (e, key, setKey) => {
    setSelectedValues((prev) => ({ ...prev, [key]: e }));
    setKey(key, e.value);
  };

  const onMultipleSelectChange = (e, key, setKey) => {
    setSelectedValues((prev) => ({ ...prev, [key]: e }));
    setKey(
      key,
      e?.map((elt) => elt?.value)
    );
  };

  const handleFileChange = (file, setFieldValue, field) => {
    // Add  thumbnail for exercise
    setFieldValue(field, file?.[0]);
  };

  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (!values?.level) values.level = Constants?.levelOptions[0]?.value;
        // Add or update exercise API
        let submitFunc = exerciseInsert(values);
        if (exerciseSingle._id) {
          values.id = exerciseSingle._id;
          submitFunc = exerciseUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.EXERCISE_SINGLE, payload: "" });
            dispatch({ type: types.EXERCISE_FORM_MODEL, payload: "" });
          }
          dispatch(exerciseList());
        });
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form className="row">
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="exerciseName"
              className="form-control"
              errors={touched?.exerciseName ? errors?.exerciseName : undefined}
              touched={touched?.exerciseName}
              label={Constants.exerciseName}
            />
          </div>
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="exerciseCode"
              className="form-control"
              errors={touched?.exerciseCode ? errors?.exerciseCode : undefined}
              touched={touched?.exerciseCode}
              label={Constants.exerciseCode}
            />
          </div>
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="exerciseCodePs"
              className="form-control"
              label={Constants.exerciseCodePs}
              notrequired="true"
            />
          </div>
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="regression"
              className="form-control"
              label={Constants.regression}
              notrequired="true"
            />
          </div>
          <div className="col-md-4 mb-2">
            <ReactSelect
              label={`${Constants.level}`}
              required={true}
              options={Constants.levelOptions}
              onChange={(e) => onSingleSelectChange(e, "level", setFieldValue)}
              value={selectedValues.level}
            />
          </div>
          <div className="col-md-4 mb-2">
            <SingleSelect
              label={`${Constants.exerciseCategory}`}
              required={true}
              options={exerciseCategorylist?.exerciseCategory || []}
              onChange={(e) =>
                onSingleSelectChange(e, "exerciseCategoryId", setFieldValue)
              }
              value={selectedValues.exerciseCategoryId}
              errors={
                touched?.exerciseCategoryId
                  ? errors?.exerciseCategoryId
                  : undefined
              }
              touched={touched?.exerciseCategoryId}
            />
          </div>
          <div className="col-md-4 mb-2">
            <SingleSelect
              label={`${Constants.exerciseGoal}`}
              required={true}
              options={exercisegoallist?.items || []}
              onChange={(e) =>
                onSingleSelectChange(e, "exerciseGoal", setFieldValue)
              }
              value={selectedValues.exerciseGoal}
              errors={touched?.exerciseGoal ? errors?.exerciseGoal : undefined}
              touched={touched?.exerciseGoal}
            />
          </div>
          <div className="col-md-4 mb-2">
            <SingleSelect
              label={`${Constants.exercisePosition}`}
              required={false}
              options={exercisepositionlist?.items || []}
              onChange={(e) =>
                onSingleSelectChange(e, "exercisePosition", setFieldValue)
              }
              value={selectedValues.exercisePosition}
            />
          </div>
          <div className="col-md-4 mb-2">
            <SingleSelect
              label={`Mechanics ${Constants.mechanicsJoint}`}
              required={false}
              options={mechanicsjointlist?.items || []}
              onChange={(e) =>
                onSingleSelectChange(e, "mechanics", setFieldValue)
              }
              value={selectedValues.mechanics}
            />
          </div>
          <div className="col-md-4 mb-2">
            <SingleSelect
              label={`${Constants.movementPattern}`}
              required={true}
              options={movementpatternlist?.items || []}
              onChange={(e) =>
                onSingleSelectChange(e, "movementPatterns", setFieldValue)
              }
              value={selectedValues.movementPatterns}
              errors={
                touched?.movementPatterns ? errors?.movementPatterns : undefined
              }
              touched={touched?.movementPatterns}
            />
          </div>
          <div className="col-md-4 mb-2">
            <SingleSelect
              label={`${Constants.force}`}
              required={true}
              options={forcelist?.items || []}
              onChange={(e) => onSingleSelectChange(e, "force", setFieldValue)}
              value={selectedValues.force}
              errors={touched?.force ? errors?.force : undefined}
              touched={touched?.force}
            />
          </div>
          <div className="col-md-4 mb-2">
            <MultipleSelect
              label={`${Constants.joint}`}
              required={true}
              options={jointlist?.items || []}
              onChange={(e) =>
                onMultipleSelectChange(e, "avoidForJoint", setFieldValue)
              }
              value={selectedValues.avoidForJoint}
              errors={
                touched?.avoidForJoint ? errors?.avoidForJoint : undefined
              }
              touched={touched?.avoidForJoint}
            />
          </div>
          <div className="col-md-4 mb-2">
            <MultipleSelect
              label={`${Constants.muscle}`}
              required={true}
              options={musclelist?.items || []}
              onChange={(e) =>
                onMultipleSelectChange(e, "avoidForMuscle", setFieldValue)
              }
              value={selectedValues.avoidForMuscle}
              errors={
                touched?.avoidForMuscle ? errors?.avoidForMuscle : undefined
              }
              touched={touched?.avoidForMuscle}
            />
          </div>
          <div className="col-md-4 mb-2">
            <MultipleSelect
              label={`${Constants.equipment}`}
              required={false}
              options={equipmentlist?.items || []}
              onChange={(e) =>
                onMultipleSelectChange(e, "equipmentTraditional", setFieldValue)
              }
              value={selectedValues.equipmentTraditional}
            />
          </div>
          <div className="col-md-4 mb-2">
            <MultipleSelect
              label={`${Constants.bodyGroup}`}
              required={true}
              options={bodygrouplist?.items || []}
              onChange={(e) =>
                onMultipleSelectChange(e, "bodyGroup", setFieldValue)
              }
              value={selectedValues.bodyGroup}
              errors={touched?.bodyGroup ? errors?.bodyGroup : undefined}
              touched={touched?.bodyGroup}
            />
          </div>
          <div className="col-md-4 mb-2">
            <MultipleSelect
              label={`${Constants.primaryJoint}`}
              required={true}
              options={primaryjointlist?.items || []}
              onChange={(e) =>
                onMultipleSelectChange(e, "primaryJoint", setFieldValue)
              }
              value={selectedValues.primaryJoint}
              errors={touched?.primaryJoint ? errors?.primaryJoint : undefined}
              touched={touched?.primaryJoint}
            />
          </div>
          <div className="col-md-4 mb-2">
            <MultipleSelect
              label={`${Constants.primaryMuscle}`}
              required={true}
              options={primarymusclelist?.items || []}
              onChange={(e) =>
                onMultipleSelectChange(e, "primarymuscles", setFieldValue)
              }
              value={selectedValues.primarymuscles}
              errors={
                touched?.primarymuscles ? errors?.primarymuscles : undefined
              }
              touched={touched?.primarymuscles}
            />
          </div>
          <div className="col-md-4 mb-2">
            <MultipleSelect
              label={`${Constants.synergistMuscle}`}
              required={true}
              options={synergistmusclelist?.items || []}
              onChange={(e) =>
                onMultipleSelectChange(e, "synergistmuscle", setFieldValue)
              }
              value={selectedValues.synergistmuscle}
              errors={
                touched?.synergistmuscle ? errors?.synergistmuscle : undefined
              }
              touched={touched?.synergistmuscle}
            />
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">{`${Constants.thumbnail}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.oneThumb && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="381"
                    height="426"
                    errors={touched?.oneThumb ? errors?.oneThumb : undefined}
                    touched={touched?.oneThumb}
                    label={`${Constants.upload} ${Constants.thumbnail}`}
                    handleFile={(files) =>
                      handleFileChange(files, setFieldValue, "oneThumb")
                    }
                    maxFiles={1}
                  />
                )}
              </div>
              <div className="after-up">
                {values && values?.oneThumb && (
                  <ViewImageFile
                    file={values?.oneThumb}
                    onClick={() => setFieldValue("oneThumb", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">{`${Constants.video}`}</label>
            <RequiredIcon />
            <div>
              {values && !values?.video169 && (
                <VideoTusUpload
                  errors={touched?.video169 ? errors?.video169 : undefined}
                  touched={touched?.video169}
                  label={`${Constants.upload} ${Constants.video}`}
                  handleFile={(video) =>
                    handleVideoChange(video, setFieldValue, "video169")
                  }
                />
              )}
              {values && values?.video169 && (
                <ViewVideoFile
                  file={values?.video169}
                  onClick={() => {
                    setFieldValue("video169", "");
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">{`${Constants.guidedVideo}`}</label>
            <div>
              {values && !values?.guidedVideoLink && (
                <VideoTusUpload
                  label={`${Constants.upload} ${Constants.guidedVideo}`}
                  handleFile={(video) =>
                    handleVideoChange(video, setFieldValue, "guidedVideoLink")
                  }
                />
              )}
              {values && values?.guidedVideoLink && (
                <ViewVideoFile
                  file={values?.guidedVideoLink}
                  onClick={() => {
                    setFieldValue("guidedVideoLink", "");
                  }}
                />
              )}
            </div>
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default ExerciseForm;
