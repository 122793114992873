import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const notificationAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.NOTIFICATION, data);
  return response;
};

export const notification = async (data) => {
  const url = `${ApiUrl.NOTIFICATION}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};
