import React from "react";
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import ProgrammeForm from "../ProgrammeForm";

const ProgrammeDetailPage = () => {
  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.detail} />
      <ProgrammeForm />
    </div>
  );
};

export default ProgrammeDetailPage;
