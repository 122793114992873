import React, { useEffect, useState } from "react";
import MultiSelect from "../Common/Fields/MultiSelect";
import RequiredIcon from "../Common/Fields/RequiredIcon";

const MultipleSelect = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props?.options?.length > 0) {
      setOptions(
        props.options?.map((elt) => ({
          value: elt._id,
          label: elt?.title || elt?.name,
        }))
      );
    }
  }, [props.options]);

  return (
    <>
      <label className="form-label">{`${props?.label}`}</label>
      {props?.required && <RequiredIcon />}
      <MultiSelect
        key="multi_select"
        options={options}
        onChange={(selected) => props?.onChange(selected)}
        value={props?.value}
        errors={props?.errors}
        touched={props?.touched}
        isSelectAll={true}
        menuPlacement={"bottom"}
      />
    </>
  );
};

export default MultipleSelect;
