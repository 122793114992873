import { types } from "../Actions/types";

const initialState = {
  workoutlist: [],
  workoutdetail: {},
  workoutexerciselist: [],
  error: null,
  workoutSingle: "",
  workoutExerciseSingle: "",
  popupmodel: "",
  workoutpopupmodel: "",
};

const Workout = (state = initialState, action) => {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.WORKOUT_LIST:
      return {
        ...state,
        workoutlist: payload,
        error,
      };
    case types.WORKOUT_SINGLE:
      return {
        ...state,
        workoutSingle: payload,
        error,
      };
    case types.WORKOUT_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };
    case types.WORKOUT_DETAIL:
      return {
        ...state,
        workoutdetail: payload,
        error,
      };
    case types.WORKOUT_ADD_EXER_SINGLE:
      return {
        ...state,
        workoutExerciseSingle: payload,
        error,
      };
    case types.WORKOUT_ADD_EXER_FORM_MODEL:
      return {
        ...state,
        workoutpopupmodel: payload,
      };

    default:
      return state;
  }
};

export default Workout;
