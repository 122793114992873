import dayjs from "dayjs";
import React from "react";
import { Constants } from "../Common/Constants";

const CardBlock = ({ user }) => {
  // Card for Created At, Name, Email, Number
  const OrderDetailData = [
    {
      mainbgColor: "alert-success",
      bgColor: "bg-success",
      icon: "icofont-ui-calendar fa-lg",

      name: Constants.joiningDate,
      secondName: `${dayjs(user?.createdAt).format(
        Constants.dateFormat
      )} at ${dayjs(user?.createdAt).format(Constants.timeFormat)}`,
    },
    {
      mainbgColor: "alert-danger",
      bgColor: "bg-danger",
      icon: "fa fa-user fa-lg",
      name: Constants.age,
      secondName: user?.age || Constants.na,
    },
    {
      mainbgColor: "alert-warning",
      bgColor: "bg-warning",
      icon: "fa fa-envelope fa-lg",
      name: Constants.email,
      secondName: user?.email || Constants.na,
    },
    {
      mainbgColor: "alert-info",
      bgColor: "bg-info",
      icon: "fa fa-phone-square fa-lg",
      name: Constants.number,
      secondName: user?.mobile || Constants.na,
    },
  ];

  return (
    <>
      <div className="">
        <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 ">
          {OrderDetailData.map((order, i) => {
            return (
              <div key={"s" + i} className="col">
                <div className={`${order.mainbgColor} alert mb-0 `}>
                  <div className="d-flex align-items-center">
                    <div
                      className={`avatar rounded no-thumbnail ${order.bgColor} text-light`}
                    >
                      <i className={order.icon}></i>
                    </div>
                    <div className="flex-fill ms-3 text-truncate">
                      <div className="h5 d-flex justify-content-between align-items-center mb-0">
                        {order.name}
                      </div>
                      <span>{order.secondName}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CardBlock;
