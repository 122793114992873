import { Constants } from "../Common/Constants";
import * as Yup from "yup";

export const initValues = {
  exerciseName: "",
  exerciseCode: "",
  exerciseCodePs: "",
  regression: "",
  level: Constants?.levelOptions[0]?.value,
  exerciseCategoryId: "",
  exerciseGoal: "",
  exercisePosition: "",
  mechanics: "",
  movementPatterns: "",
  force: "",
  thumbnail: "",
  avoidForMuscle: [],
  avoidForJoint: [],
  equipmentTraditional: [],
  bodyGroup: [],
  primaryJoint: [],
  primarymuscles: [],
  synergistmuscle: [],
  oneThumb: "",
  video169: "",
  guidedVideoLink: "",
};

export const selectedInitValues = {
  level: Constants.levelOptions[0],
  exerciseCategoryId: "",
  exerciseGoal: "",
  exercisePosition: "",
  mechanics: "",
  movementPatterns: "",
  force: "",
  avoidForMuscle: [],
  avoidForJoint: [],
  equipmentTraditional: [],
  bodyGroup: [],
  primaryJoint: [],
  primarymuscles: [],
  synergistmuscle: [],
};

// Validations for Exercise form
export const validationSchema = Yup.object().shape({
  exerciseName: Yup.string().required(Constants.required),
  exerciseCode: Yup.string().required(Constants.required),
  exerciseCategoryId: Yup.string().required(Constants.required),
  exerciseGoal: Yup.string().required(Constants.required),
  movementPatterns: Yup.string().required(Constants.required),
  force: Yup.string().required(Constants.required),
  avoidForMuscle: Yup.array().min(1, Constants.required),
  avoidForJoint: Yup.array().min(1, Constants.required),
  bodyGroup: Yup.array().min(1, Constants.required),
  primaryJoint: Yup.array().min(1, Constants.required),
  primarymuscles: Yup.array().min(1, Constants.required),
  synergistmuscle: Yup.array().min(1, Constants.required),
  oneThumb: Yup.string().required(Constants.required),
  video169: Yup.string().required(Constants.required),
});
